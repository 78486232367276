<template>
    <div class="py-0 mt-4 mb-8">
        <v-container class="mx-0 my-0" style="padding:0 !important; max-width: 100% !important">
            
            <!-- Confirm Products -->
            <v-card class="mt-1 pt-1 pb-10 px-0 size" style="margin-bottom: 35px !important" align="center">
                <v-row class="px-3 py-4 text-center" justify="center" align="center">
                    <v-col cols="8" class="py-1" justify="center" align="center">
                        <span justify="center" class="pr-1 secondary--text azul--text text-uppercase font-weight-bold" style="font-size:18px">
                            {{ $t('end_process.process_container') }} 
                        </span> 
                    </v-col>
                </v-row>
                <v-col cols="8" justify="start" align="start" class="px-0">
                    <v-row>
                        <v-col cols="7" class="py-1">
                            <span justify="center" class="pr-1 azul--text text--uppercase font-weight-bold" style="font-size:18px">
                                {{ $t('end_process.general_data') }} 
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('end_process.appointment') }}: </span> {{ this.id }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('end_process.client') }}: </span> {{ this.appointment.client.name }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('end_process.estimated_date_of_arriving') }}: </span> {{ this.appointment.arrival_date }} </v-col>
                    </v-row><v-row>
                        <v-col v-if="$store.state.language == 'es'" cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('register_in_process.operation') }}: </span> {{ this.appointment.operation.description }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="$store.state.language == 'en'" cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('register_in_process.operation') }}: </span> {{ this.appointment.operation.description1 }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('end_process.container') }}: </span> {{ this.appointment.container_number }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('end_process.description') }}: </span> {{ this.appointment.description }} </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0"><span justify="center" class="pr-1 secondary--text font-weight-bold">{{ $t('end_process.comments') }}: </span> {{ this.appointment.commentary }} </v-col>
                    </v-row>
                </v-col>
                <v-col class="py-5 px-0" cols="8" justify="start" align="start">
                    <v-row justify="center" align="center">
                        <v-col cols="6" >
                            <span justify="center" class="pr-1 azul--text font-weight-bold" style="font-size:18px">
                                {{ $t('register_in_process.products') }} 
                            </span>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row class="py-0 my-0 mx-0" justify="center" align="center" v-if="appointment.operation_id % 2 == 0">
                        
                        <v-col cols="3" class="mr-3 py-0 my-0 px-0">
                            <label style="color: #707070; display: block;" class="mr-1 my-0 py-0" for="">{{ $t("end_process.lines") }}: {{ rfid_summary.lines }} / {{ rfid_summary.total_lines }}</label>
                            <label style="color: #707070; display: block;" class="mr-1 my-0 py-0" for="">{{ $t("end_process.surplus") }}: {{ rfid_summary.surplus }} / {{ rfid_summary.total_surplus }}</label>
                        </v-col>

                        <v-spacer></v-spacer>                        
                        
                        <v-col cols="3" class="mr-3 py-0 my-0 px-0">
                            <label style="color: #707070; display: block;" class="mr-1 my-0 py-0" for="">{{ $t("end_process.pieces") }}: {{ rfid_summary.pieces }} / {{ rfid_summary.total_pieces }}</label>
                            <label style="color: #707070; display: block;" class="mr-1 my-0 py-0" for="">{{ $t("end_process.surplus_pieces") }}: {{ rfid_summary.surplus_pieces }} / {{ rfid_summary.total_surplus_pieces }}</label>
                        </v-col>

                        <v-spacer></v-spacer>
                        
                        <v-col cols="3" class="mr-3 py-0 my-0" >
                            <v-row justify="end" align="center">
                                <label style="color: #707070" class="mt-2 mr-1" for="">{{ $t("end_process.remove") }}</label>
                                <v-switch :label="$t('end_process.add')" color="amarillo" v-model="add_tags"></v-switch>
                            </v-row>
                        </v-col>
                    </v-row>
                    <datatable :props="{ headers: (appointment.is_entry ? headers_sku : get_headers()), items: appointment.products }" :hide_pagination="true" @methodHandler="methodHandler"></datatable>
                    <br>
                    <v-row align="center" justify="center">
                        <v-col class="mb-1" cols="2" align="center" justify="center">
                            <v-btn color="accent" class="black--text mx-2" outlined @click="back" style="width:100%">{{ $t('end_process.cancel') }}</v-btn>
                        </v-col> 
                        <v-col class="mb-1" cols="2" align="center" justify="center">
                            <v-btn color="accent" class="black--text mx-2" outlined :disabled="appointment.operation.format_type == 'Entrada'" @click="clean" style="width:100%">{{ $t('end_process.clean') }}</v-btn>
                        </v-col> 
                        <v-col class="mb-1" cols="4" align="center" justify="center">
                            <v-btn color="amarillo" outlined class="white--text mx-2" @click="toOnPremises" style="width:100%" v-permission="'op_in_process.update'"> {{ $t('register_in_process.to_on_premises') }}</v-btn>
                        </v-col> 
                        <v-col class="mb-1" cols="4" align="center" justify="center">
                            <v-btn color="amarillo" class="white--text mx-2" @click="update" :disabled="!can_continue" style="width:100%" v-permission="'op_in_process.update'">{{ $t('end_process.ready_to_go') }}</v-btn>
                        </v-col> 
                    </v-row>   
                </v-col>
            </v-card>
            <v-dialog v-model="modalDelete" persistent max-width="90%">
            <v-card >
                <v-card-title class="justify-center">
                    <v-row align="center" justify="center">
                        <h3 class="text-uppercase" style="color: #00587d;">
                            {{ $t('end_process.delete_detail') }}
                        </h3>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row align="center" justify="center" class="py-3 my-3 text-center">
                        <v-col cols="12">
                            <h4>{{ $t('end_process.remove_detail') }}</h4>
                        </v-col>
                    </v-row>
                    
                    <div class="d-flex" style="justify-content: center;">
                        <v-btn color="accent" outlined class="white--text mr-3" @click="modalDelete = false; detail_to_delete = ''">
                            {{ $t('end_process.cancel') }}
                        </v-btn>
                        <v-btn color="error" class="white--text" @click="reduce_expected(detail_to_delete)">
                            {{ $t('end_process.remove') }}
                        </v-btn>
                    </div>
                </v-card-text> 
                <div class="marker"></div>
            </v-card>
        </v-dialog>
            <bottom-bar ref="bottombar" :from="from" :m_type="m_type"></bottom-bar>
        </v-container>
        <v-snackbar v-model="snackbar.visible" :timeout="25000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
import io from 'socket.io-client'

export default {
    computed: {
        headers_sku: function(){
            return [
                {text: this.$t('process_pendings.sku'), value: 'sku_p', class: 'text-uppercase'},
                {text: this.$t('process_pendings.pieces'), value: 'quantity', class: 'text-uppercase'},
                {text: this.$t('process_pendings.unit'), value: 'unit', class: 'text-uppercase'},
            ];
        },
        rules_required(){return [v => !!v || v == 0 || this.$t('create_appointment.validations.required_field')];},
        numeric_required(){
            return [
                v => (/^(0|[1-9][0-9]{0,9})$/).test(v) || this.$t('register_arrivals.validations.numbers'),
                v => !!v || v == 0 || this.$t('edit_appointment.validations.required_field')
            ];
        },
    },
    data(){
        return {
            id: this.$route.params.id,
            from: this.$route.params.from,
            m_type: this.$route.params.m_type,
            snackbar: {
                visible: false,
                color: 'primary',
                timeout: 2000,
                text: ''
            },
            socket: false,
            appointment:{
                // General data
                client_id: "",
                is_rfid: false,
                is_entry: false,
                client: {
                    name:"",
                    client_type: "DEFAULT",
                },
                arrival_date: "",
                eta: "",
                operation_id: "",
                operation: {
                    format_type: "",
                    description: "",
                    description1: ""
                },
                status: "",
                container_number: "",
                description: "",
                commentary: "",
                additional_services: "",
                purchase_order: "",
                is_national: "",
                products: [],
                products_length: "",
                gate: ""
            },
            barcode: "",
            actions_s: [ // Outcome movements (Salidas)
                {
                    name: this.$t('end_process.checked'),
                    icon: {
                        color: 'warning',
                        icon: 'check_circle_outline'
                    },
                    show_rfid: true,
                    is_enabled: false,
                    has_action: false,
                    permission: "operation.update"
                },
                {
                    name: this.$t('end_process.accept_product'),
                    icon: {
                        color: 'error',
                        icon: 'playlist_add_check'
                    },
                    method: "adjust_scanned",
                    show_rfid: true,
                    is_enabled: false,
                    has_action: true,
                    permission: "operation.update"
                },
                {
                    name: this.$t('end_process.add_product'),
                    icon: {
                        color: 'error',
                        icon: 'playlist_add'
                    },
                    method: "adjust_expected",
                    show_rfid: true,
                    is_enabled: false,
                    has_action: true,
                    permission: "operation.update"
                },
                {
                    name: this.$t('end_process.update_expected_or_remove'),
                    icon: {
                        color: 'black',
                        icon: 'playlist_remove'
                    },
                    method: "showModalRemove",
                    show_rfid: true,
                    is_enabled: true,
                    has_action: true,
                    permission: "operation.update"
                },
            ],
            can_continue: false,
            load: false,
            detail_to_delete: "",
            modalDelete: false,
            add_tags: true,
            rfid_summary: {
                lines: 0,
                total_lines: 0,
                pieces: 0,
                total_pieces: 0,
                surplus: 0,
                total_surplus: 0,
                surplus_pieces: 0,
                total_surplus_pieces: 0
            }
        }
    },
    beforeDestroy() {
        if (this.socket) this.socket.disconnect();
    },
    mounted(){
        localStorage.setItem('bottom', 3);
        this.$store.state.module = "headers.end_process_operation";
        this.$store.state.bottom = 3;

        let self = this; 

        const keyPressHandler = (e) => {
            if(self.last == true && /^\S+$/.test(e.key)) { 
                self.barcode = e.key;
                self.last = false;
            }
            else if(e.code == "Enter") {
                self.last = true;
                self.updateList();
            }
            else if(/^\S+$/.test(e.key)) self.barcode += e.key;
        };

        window.addEventListener ('keypress', keyPressHandler, { capture: true });

        this.index();
    },
    methods: {
        create_connection(gateChannel){
            this.socket = io(this.$store.state.rfidApi)
            this.socket.on('connect', () => {                
                this.socket.emit('joinGate', gateChannel);
            
                this.socket.on('event', (data) => {
                    console.log(data);
                    this.appointment.products = [];

                    this.rfid_summary = {
                        lines: 0,
                        total_lines: 0,
                        pieces: 0,
                        total_pieces: 0,
                        surplus: 0,
                        total_surplus: 0,
                        surplus_pieces: 0,
                        total_surplus_pieces: 0
                    }

                    data.info.details.forEach(x => {
                        var ac = this.actions_s;        
                        var p = {};

                        p = {
                            sku_p: x.sku,
                            unit: x.unit,
                            product_id: x.product_id,
                            expected: x.real,
                            readed: x.read,
                            location_id: parseInt(x.location_id),
                            location: x.location,
                            pallet: parseInt(x.pallet),
                            box: x.box,
                            is_changed: x.is_changed,
                            batch: x.batch,
                            expiration: x.expiration ?? x.reference,
                            actions: JSON.parse(JSON.stringify(ac)),
                            parameters: x.id
                        }

                        this.update_rfid_summary(x);
                        p = this.validate_actions(x, p);
                        
                        this.appointment.products.push(p);
                    })

                    this.check_ok_for_all_products();
                })
            })
        },
        get_headers: function(){
            var headers = [

            {text: this.$t('process_pendings.sku'), value: 'sku_p', class: 'text-uppercase'},
                {text: this.$t('process_pendings.location'), value: 'location', class: 'text-uppercase'},
                {text: this.$t('process_pendings.pallet'), value: 'pallet', class: 'text-uppercase'},
                {text: this.$t('process_pendings.batch'), value: 'batch', class: 'text-uppercase'},
                {text: this.$t('process_pendings.expiration'), value: 'expiration', class: 'text-uppercase'},
                {text: this.$t('process_pendings.expected'), value: 'expected', class: 'text-uppercase'},
                {text: this.$t('process_pendings.readed'), value: 'readed', class: 'text-uppercase'},
                {text: '', value: 'actions', class: 'text-uppercase', width:"140px"},
            ]

            if(this.appointment.client.client_type != undefined && this.appointment.client.client_type == 'HD'){
                headers[3].text = this.$t('store_product.part_number');
                headers[4].text = this.$t('store_product.weight');
            }

            return headers;
        },
        update_rfid_summary(x){
            if(x.real == x.read && x.expected > 0) this.rfid_summary.lines++;
            if(x.expected > 0) {
                this.rfid_summary.total_lines++;
                this.rfid_summary.pieces += x.read;
                this.rfid_summary.total_pieces += x.real;
            }

            if(x.expected == 0 && x.read == x.real) this.rfid_summary.surplus++;
            if(x.expected == 0){
                this.rfid_summary.total_surplus++;
                this.rfid_summary.surplus_pieces += x.read;
                this.rfid_summary.total_surplus_pieces += x.real;
            }
        },
        validate_actions(x, p){
            if(x.read == x.real){
                p["actions"][0].is_enabled = true;
                p["actions"][0].icon.color = "success2";
                
                p["actions"][3].is_enabled = false;
            }
            else if(x.read > 0 && x.read != x.real){
                p["actions"][0].is_enabled = true;
                p["actions"][0].icon.color = "warning";

                p["actions"][3].is_enabled = false;
            }

            if(x.read > x.real){ 
                p["actions"][1].is_enabled = true;
                p["actions"][2].is_enabled = true;
                
                p["actions"][3].is_enabled = false;
            }
            else if(x.read < x.real){
                p["actions"][1].is_enabled = false;
                p["actions"][2].is_enabled = false;
                
                p["actions"][3].is_enabled = true;
            }

            return p;
        },
        check_ok_for_all_products(){
            const result = this.appointment.products.filter(x => x.actions[0].icon.color != "success2")

            if(result.length == 0) this.can_continue = true;
            else this.can_continue = false;
        },
        back(){
            this.$router.go(-1);
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'appointments/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    response.data = response.data.data.attributes;
                    // General Data
                    this.$store.state.clientId              = response.data.client.id ?? null;
                    this.appointment.client_id              = response.data.client.id ?? null;
                    this.appointment.client                 = response.data.client ?? null;
                    this.appointment.operation              = response.data.operation ?? null;
                    this.appointment.arrival_date           = response.data.arrival_date.substring(0, 10) ?? null;
                    if(response.data.is_national == true && response.data.eta != undefined && response.data.eta != null)
                        this.appointment.eta                = response.data.eta.substring(0, 10);
                    this.appointment.operation_id           = response.data.operation.id ?? null;
                    this.appointment.statuis                = response.data.status;
                    this.appointment.additional_services    = response.data.additional_services ?? null;
                    this.appointment.purchase_order         = response.data.purchase_order ?? null;
                    this.appointment.container_number       = response.data.container_number ?? null;
                    this.appointment.description            = response.data.description ?? null;
                    this.appointment.commentary             = response.data.commentary ?? null;
                    this.appointment.status                 = response.data.status ?? null;
                    this.appointment.is_national            = response.data.is_national ?? false;
                    this.appointment.is_rfid                = response.data.is_rfid ?? false;
                    this.appointment.gate                   = response.data.gate ?? null;
                    this.appointment.is_entry               = (response.data.operation.format_type == "Entrada" ? true : false);
                    
                    if(response.data.operation.format_type == "Entrada"){
                        if(response.data.unknown_products == true){
                            this.can_continue = true;

                            if(response.data.standby_products[0].attributes.unknownProducts.boxes > 0){
                                this.appointment.products.push({
                                    sku_p: this.$t('process_pendings.boxes'),
                                    quantity: response.data.standby_products[0].attributes.unknownProducts.boxes
                                })
                            }
                            else if(response.data.standby_products[0].attributes.unknownProducts.pallets > 0){
                                this.appointment.products.push({
                                    sku_p: this.$t('process_pendings.pallets'),
                                    quantity: response.data.standby_products[0].attributes.unknownProducts.pallets
                                })
                            }
                            else if(response.data.standby_products[0].attributes.unknownProducts.containers > 0){
                                this.appointment.products.push({
                                    sku_p: this.$t('process_pendings.containers'),
                                    quantity: response.data.standby_products[0].attributes.unknownProducts.containers
                                })
                            }
                        }
                        else{
                            if(response.data.standby_products.length > 0) this.can_continue = true;
                            response.data.standby_products.forEach(x => 
                            {
                                x = x.attributes;
                                var p = {};
                                p = {
                                    sku_p: x.product.sku,
                                    quantity: x.quantity,
                                    unit: x.product.unit
                                }
                                            
                                this.appointment.products.push(p);
                            });
                        }
                    } 
                    else if(response.data.operation.format_type == "Salida") this.create_connection(response.data.gate.id);
                }    
            })
            .catch((response) => {              
                this.$store.state.loaderProps.visible = false;
                console.log(response)
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        toOnPremises(){
            this.appointment.newStatus = 'En recinto';
            this.appointment.status = 'En recinto';
            this.appointment.gate_id = null;
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.put(this.$store.state.chronosApi + 'setStatus/' + this.id, this.appointment, {headers: {Authorization: lsToken}})
            .then((response) => {   
                if(response.status === 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('register_in_process.responses.correct')
                    } 

                    this.$router.push('/operation/on-premises/' + this.from + '/' + this.m_type);
                }
            }).catch((error) => {
                console.log(error);
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('register_in_process.responses.update')
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        async update(){
            if(this.appointment.operation.format_type == "Salida"){
                const data = { reader_name: this.appointment.gate.id }

                this.$store.state.loaderProps.visible = true;
                const lsToken = localStorage.getItem("access_token");
                await this.$http.post(this.$store.state.rfidApi + 'api/rfid/gate_event/complete', data, {headers: {Authorization: lsToken}});

                this.$store.state.loaderProps.visible = false;
                
                this.$router.push('/operation/pending-out/' + this.from + '/' + this.m_type);
            }
            else {
                if(this.appointment.products.length <= 0){
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('end_process.empty_skus')
                    } 
                }

                const lsToken = localStorage.getItem("access_token");
                this.appointment.newStatus = 'Pendiente de salida';
                this.appointment.status = 'Pendiente de salida';
                this.appointment.products_length = this.appointment.products.length;
                this.$store.state.loaderProps.visible = true;
                
                // Update appointment status
                this.$http.put(this.$store.state.chronosApi + 'setStatus/' + this.id, this.appointment, {headers: {Authorization: lsToken}})
                .then((response) => {   
                    if(response.status === 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('end_process.responses.correct')
                        } 

                        this.$router.push('/operation/pending-out/' + this.from + '/' + this.m_type);
                    }
                }).catch((error) => {
                    console.log(error);
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('end_process.responses.update')
                    }
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
        },
        async adjust_scanned(id){
            const data = {
                reader_name: this.appointment.gate.id,
                detail_id: id,
                tag_reads: [
                    // { epc: this.barcode } // show modal to scann the epc again
                ]
            }

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            await this.$http.post(this.$store.state.rfidApi + 'api/rfid/gate_event/accept', data, {headers: {Authorization: lsToken}});

            this.$store.state.loaderProps.visible = false;
        },
        async adjust_expected(id){
            const data = {
                reader_name: this.appointment.gate.id,
                detail_id: id
            }

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            await this.$http.post(this.$store.state.rfidApi + 'api/rfid/gate_event/add', data, {headers: {Authorization: lsToken}});

            this.$store.state.loaderProps.visible = false;
        },
        showModalRemove(id){
            this.modalDelete = true;
            this.detail_to_delete = id;
        },
        async reduce_expected(id){
            const data = {
                reader_name: this.appointment.gate.id,
                detail_id: id
            }

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            await this.$http.post(this.$store.state.rfidApi + 'api/rfid/gate_event/delete', data, {headers: {Authorization: lsToken}});

            this.$store.state.loaderProps.visible = false;
            this.detail_to_delete = "";
            this.modalDelete = false;
        },
        async updateList(){
            if (this.barcode.startsWith('P') || this.barcode.startsWith('p')) {
                this.barcode = this.barcode.substring(1);  
                this.barcode = 'C' + this.barcode.padStart(23, '0'); 
            }
            if (this.barcode.startsWith('T') || this.barcode.startsWith('t')) {
                this.barcode = this.barcode.substring(1);  
                    this.barcode = 'A' + this.barcode.padStart(23, '0'); 
            }

            const data = {
                reader_name: this.appointment.gate.id,
                tag_reads: [
                    { epc: this.barcode }
                ]
            }

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            if(this.add_tags)
                await this.$http.post(this.$store.state.rfidApi + 'api/rfid/gate_event', data, {headers: {Authorization: lsToken}});
            else
                await this.$http.post(this.$store.state.rfidApi + 'api/rfid/gate_event_remove', data, {headers: {Authorization: lsToken}});

            this.$store.state.loaderProps.visible = false;
        },
        async clean(){
            const data = {
                reader_name: this.appointment.gate.id
            }

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            await this.$http.post(this.$store.state.rfidApi + 'api/rfid/delete_gate_event', data, {headers: {Authorization: lsToken}});

            this.$store.state.loaderProps.visible = false;

            window.location.reload();
        }
    },
    watch:{}
}
</script>