<template>
    <div class="back align-center">
        <v-container fluid >
            <v-row justify="center" align="center" class="px-0" style="overflow-y: auto !important;">
                <v-col class="left_ballon px-0">
                    <v-col justify="center" align="center">
                        <v-img src="/img/imagen.png" class="img-p"></v-img>
                        <v-img class="img-p" width="250px" max-width="250" height="80" src="/img/logotipo-chronos.png"></v-img>
                    </v-col>
                </v-col>
                <v-col class="right_ballon" justify="center" align="center">
                    <v-col class="cont px-5" justify="center" align="center">
                        <br>
                        <v-img width="250" height="32.5" src="/img/Bienvenidos.svg"></v-img>
                        <br>
                        <br>
                        <v-form ref="form" v-model="valid" lazy-validation v-if="login">
                                <v-card tile flat>
                                    <v-card-text class="py-0">
                                        <v-row>
                                            <v-col class="py-0 px-0">
                                                <v-text-field
                                                    color="darkblue"
                                                    hide-details
                                                    v-model="user"
                                                    :label="$t('login.email')"
                                                    :rules="rules_email"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <br>
                                        <br>
                                        <v-row>
                                            <v-col class="py-0 px-0">
                                                <v-text-field
                                                    color="darkblue"
                                                    hide-details
                                                    v-model="password"
                                                    :append-icon="show ? 'visibility' : 'visibility_off'"
                                                    :type="show ? 'text' : 'password'"
                                                    :label="$t('login.password')"
                                                    :rules="rules_password"
                                                    @click:append="show = !show"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                                <br>
                                <v-row>
                                    <v-col class="pt-10 text-center">
                                        <v-btn color="darkblue" dark @click="loginApi">{{ $t('login.log_in') }}</v-btn>
                                    </v-col>
                                </v-row>
                                <!-- <v-row justify="center">
                                    <div class="pt-4 pb-0">
                                        <v-btn text small @click="login = !login">¿Olvidaste tu contraseña?</v-btn>
                                    </div>
                                </v-row> -->
                                <br>
                                <!-- <v-row>
                                    <v-col class="py-1">
                                        <v-switch v-model="rememberMe" label="Recuerdame"></v-switch>
                                    </v-col>
                                </v-row> -->
                            </v-form>
                            <v-form ref="restore_form" v-model="valid" lazy-validation v-else>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="user"
                                            append-icon="alternate_email"
                                            label="Email"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row justify="end" align="center">
                                    <v-col>
                                        <v-btn text small color="darkblue" @click="login = !login"><v-icon left small>keyboard_backspace</v-icon>   Regresar</v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-btn color="darkblue" dark>Restablecer</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                    </v-col>
                    <v-row cols="12" justify="center" align="center" class="my-2">
                        <span class="white--text font-weight-bold overline my-0 py-0" style="font-style: italic;">Powered by</span><pre> </pre>
                        <span class="white--text font-weight-bold overline my-0 py-0">YNTECH</span>
                    </v-row>
                </v-col>
            </v-row>               
        </v-container>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>

export default {
    computed:{
        rules_email(){
            return [
                v => !!v || this.$t('login.validations.email'),
                v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || this.$t('login.validations.wrong_email')
            ];
        }, 
        rules_password() {
            return [
                v => !!v || this.$t('login.validations.password'),
            ]
        },  
    },
    data: () => ({
        login: true,
        loginIn: false,
        valid: false,
        show: false,
        rememberMe: false,
        user: '',
        password: '',
        loginError: false,
        errorText: '',
        snackbar: {
            visible: false,
            color: 'primary',
            text: ''
        },
    }),
    mounted(){
        if(this.$store.state.loggedIn != false) this.$router.push({ name: 'Dashboard' })
    },
    methods: {
        loginApi(){
            if(this.$refs.form.validate()){
                this.loginIn = true
                this.loginError = false
                this.$http.post(this.$store.state.chronosApi + 'users/sign_in', {user:{
                    email: this.user,
                    password: this.password
                }})
                .then((response) => {                   
                    if(response.data){
                        if(response.data.resource.data.attributes.image != null) this.$store.state.loggedPicture = response.data.resource.data.attributes.image;
                        const access_token = response.headers.authorization;
                        localStorage.setItem('access_token', access_token);
                        this.$store.state.loggedUsername = response.data.resource.name + ' ' + response.data.resource.data.attributes.paternal_surname + ' ' + response.data.resource.data.attributes.maternal_surname;
                        this.$store.state.loggedUserRole = [];
                        response.data.resource.data.attributes.roles.forEach( x => {
                            this.$store.state.loggedUserRole.push({
                                id: x.attributes.id,
                                name: x.attributes.name
                            })
                        })
                        this.$store.state.loggedUserEmail = response.data.resource.data.attributes.email;
                        this.$store.state.loggedUserPermissions = [];
                        response.data.resource.data.attributes.roles[0].attributes.permissions.forEach( x => {
                            this.$store.state.loggedUserPermissions.push(x.attributes.name);
                        });
                        if(response.data.resource.data.attributes.client != null && response.data.resource.data.attributes.client != undefined)
                          this.$store.state.clientUserIsCod = (response.data.resource.data.attributes.client.attributes.fulfillment == "cod" || response.data.resource.data.attributes.client.attributes.fulfillment == 'all') ? true : false;        
                        this.$store.state.clientUserId = response.data.resource.data.attributes.client_id;
                        this.$store.state.clientShippingServices = response.data.resource.data.attributes.shipping_services;
                        this.$gates.setPermissions( this.$store.state.loggedUserPermissions );
                        if(this.$store.state.clientUserId != null && this.$store.state.clientUserId != undefined) this.$router.push({ name: 'customerDashboard' })
                        else this.$router.push({ name: 'Dashboard' })
                    }
                })
                .catch((error) => {
                    this.loginError = true;
                    console.log(error);

                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('login.responses.error')
                    } 
                })
                .finally(() => {
                    this.loginIn = false
                })
            }
        }
    }
}
</script>

<style lang="scss">
    .back{
        height: 100%;
        width: 100%;
        display: flex;
		background: linear-gradient(0deg, rgba(15,64,109,1) 50%, rgba(38,107,145,1) 100%);
    }

    .left_ballon{
        height:500px;
        width:500px;
        max-width: 500px !important;
        background-color:rgba(12,50,79,1);
        padding: 40px !important;
        padding-top: 15px !important;
        padding-left: 0 !important;
        margin-right: -40px;
        z-index: 1;
        -webkit-clip-path: path('M40,0 L500,0 Q460,0 460,40 L460,460 Q460,500 420,500 L40,500 Q0,500 0,460 L0,40 Q0,0 40,0 z');
        clip-path: path('M40,0 L500,0 Q460,0 460,40 L460,460 Q460,500 420,500 L40,500 Q0,500 0,460 L0,40 Q0,0 40,0 z');
    }

    .right_ballon{
        height: 500px;
        width: 500px;
        max-width: 500px !important;
        background-color:rgba(119,166,189,1);
        padding: 4em !important;
        padding-left: 6em !important;
        margin-left: -40px;
        z-index: 1;
        -webkit-clip-path: path('M80,0 L460,0 Q500,0 500,40 L500,460 Q500,500 460,500 L0,500 Q40,500 40,460 L40,40 Q40,0 80,0 z');
        clip-path: path('M80,0 L460,0 Q500,0 500,40 L500,460 Q500,500 460,500 L0,500 Q40,500 40,460 L40,40 Q40,0 80,0 z');
    }

    .cont{
        border-radius: 40px;
        background-color: white;
    }
    .img-p{
        margin-left: -23px;
    }
</style>