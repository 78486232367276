<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center" v-if="appointment.client">    
                        <h3 class="azul--text text-uppercase">{{ $t('preload.preload') }} {{ this.id }} {{ this.appointment.client }} </h3>
                    </v-col>
                </v-row>

                <v-row class="px-5 " justify="center" align="center">
                    <v-col cols="6" class="py-0">
                        <v-row class="px-0 py-0 my-0" justify="center" align="center">
                            <v-col cols="12" class="py-0" style="display:flex">
                                <v-checkbox class="mt-0 mx-0" color="info" v-model="unknown_products" :label="$t('preload.unknown_products')"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-card-text class="px-0" v-if="unknown_products">
                    <v-form ref="formsku" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="unknown_product.type" :items="unknown_products_options" item-value="value" item-text="text" :label="$t('preload.product')" :rules="rules_required"></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="unknown_product.quantity" :label="$t('preload.quantity')" :rules="numeric_required"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="px-5 py-0 mb-0 mt-4" justify="center" align="center">
                        <v-col cols="6" class="py-0" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('preload.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save">
                                {{ $t('preload.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-text class="px-0" v-if="!unknown_products">
                    <v-form ref="formsku" lazy-validation @submit.prevent="save">
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="product.id" :items="products" item-value="id" item-text="sku" :label="$t('preload.product')" :rules="rules_required"></v-autocomplete> 
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 my-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="product.quantity" :label="$t('preload.quantity')" :rules="numeric_required"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>

                    <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0" justify="end" align="end">
                            <v-btn class="right white--text mr-3" color="amarillo" outlined @click="modal_import = true">
                                {{ $t('store_product.import_distribution') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" outlined @click="addProduct">
                                {{ $t('preload.add') }}
                            </v-btn>
                        </v-col>
                    </v-row>

                    <!-- <v-row class="px-5 py-0 my-0" justify="center" align="center">
                        <v-col cols="6" class="py-0" justify="end" align="end">
                            <v-btn class="right white--text" color="amarillo" outlined @click="addProduct">
                                {{ $t('preload.add') }}
                            </v-btn>
                        </v-col>
                    </v-row> -->

                    <v-row class="px-5 py-0 my-0" justify="center" aling="center">
                        <v-col cols="6" class="py-0" justify="center" aling="center">
                            <datatable :props="{ headers: headers, items: skus }" @methodHandler="methodHandler"></datatable>
                        </v-col>
                    </v-row>
                    <v-row class="px-5 py-0 mb-0 mt-4" justify="center" align="center">
                        <v-col cols="6" class="py-0" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('preload.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save">
                                {{ $t('preload.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>
        <v-dialog v-model="modal_import" persistent max-width="90%" >
            <v-card style="min-width: 500px">
            <v-card-title class="justify-center">
                {{ $t('store_product.import_distribution') }}
            </v-card-title>
                <v-stepper v-model="stepper">
                    <v-stepper-items>
                        <v-stepper-content step="1" class="mx-0 px-0">
                            <v-card flat class="mb-5">
                                <v-form ref="form2" lazy-validation>
                                    <v-row class="mx-0 px-0" justify="center" align="center">
                                        <v-col cols="10">
                                            <v-file-input v-model="file_products" color="amarillo" accept=".csv" :clearable="true" :label="$t('edit_appointment.multiple_skus')" :rules="rules_required"></v-file-input>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn class="mx-0" small color="amarillo" v-bind="attrs" v-on="on" @click="stepper = 4">
                                                        <v-icon class="material-icons-outlined" aria-hidden="false" color="white" small>
                                                            help
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('fulfillments_cod_returns.help') }}</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card>
                            <v-row class="mx-2 px-5 mx-5 py-0" justify="end" align="end">
                                <v-col cols="2" justify="end" align="end" class="mr-12">
                                    <v-btn text  @click="modal_import = false;" >
                                        {{ $t('fulfillments_cod_returns.cancel') }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="2" justify="end" align="end" class="mr-4">
                                    <v-btn class="white--text" color="amarillo" @click="addMultipleSkus" >
                                        {{ $t('fulfillments_cod_returns.load') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-stepper-content>

                        <v-stepper-content step="4">
                            <p class="py-0 my-0">1.- Conserve todos los encabezados en el orden presentado y sin modificarlos.</p> 
                            <p class="py-0 my-0">2.- Llene la información de acuerdo al campo solicitado, no utilice comas en ninguno de los campos, evite el uso de signos de interrogación, comillas o signos de admiración.</p> 
                            <p class="py-0 my-0">3.- A continuación se muestra una línea de ejemplo para familiarizarse con el formato.</p> 
                            <p class="py-0 my-0">4.- El archivo de productos debe ser formato CSV.</p> 
                            <p class="py-0 my-0">5.- El formato de la fecha debe ser AAAA-MM-DD.</p> 
                            <p class="py-0 my-0">Columnas: </p>
                            <v-simple-table fixed-header>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left" style="padding: 0 5px !important;">sku</th>
                                            <th class="text-left" style="padding: 0 5px !important;">cantidad</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>THISISSKU01</td>
                                            <td>200</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-row align="center" justify="center" class="my-3">
                                <v-btn color="amarillo" class="white--text" @click="stepper = 1">
                                    {{ $t('products.go_back') }}
                                </v-btn>
                            </v-row>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card>
        </v-dialog>
        <v-dialog v-model="modal_sum" max-width="250px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t('preload.add_quantity_to_product') }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                        <v-text-field v-model="to_sum" :label="$t('preload.quantity')"></v-text-field>
                        </v-col>
                    </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="modal_sum = false;">{{ $t('preload.cancel') }}</v-btn>
                    <v-btn color="blue darken-1" text @click="saveUpdateQuantity">{{ $t('preload.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        client: function(){
            return this.$store.state.clientId
        },
        rules_required(){return [v => !!v || this.$t('preload.validations.required_field')]}, 
        numeric_required(){
            return [
                v => /^(|\d)+$/.test(v) || this.$t('edit_appointment.product_details.validations.numbers'),
                v => !!v || this.$t('edit_appointment.validations.required_field')
            ];
        },
        headers: function(){
            return [
                {text: this.$t('preload.sku'), value: 'sku', class: 'text-uppercase'},
                {text: this.$t('preload.quantity'), value: 'quantity', class: 'text-uppercase'},
                {text: '', value: 'actions', class: 'text-uppercase', width:'10%', align: 'center', sortable: false},
            ];
        }
    },
    data(){
        return {
            from: this.$route.params.from,
            id: this.$route.params.id,
            m_type: this.$route.params.m_type,
            stepper: 1,
            sTHeaders: [],
            readRows: [],
            modal_import: false,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            appointment: {

            },
            product: {
                id: "",
                quantity: ""
            },
            unknown_products: false,
            unknown_products_options: [
                { value: "pallets", text: this.$t('preload.pallets') }, 
                { value: "boxes", text: this.$t('preload.box') },
                { value: "containers", text: this.$t('preload.container') }
            ],
            unknown_product: {
                type: "",
                quantity: ""
            },
            to_sum: "",
            product_to_sum: "",
            modal_sum: false,
            products: [], //catalogue of products
            skus: [], // appointments products
            actions: [
                {
                    name: this.$t('preload.sum'),
                    icon: {
                        color: 'success2',
                        icon: 'control_point'
                    },
                    method: 'sum',
                },
                {
                    name: this.$t('preload.remove'),
                    icon: {
                        color: 'error',
                        icon: 'clear'
                    },
                    method: 'removeProduct',
                }
            ],
            file_products: [],
            inputFile: [],
            readFile: '',
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.preload";
    },
    methods: {
        async index(){
            await this.load_data(false);
        },
        async load_data(reload){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const {data} = await this.$http.get(this.$store.state.chronosApi + 'appointments/standby_products/' + this.id, {headers: {Authorization: lsToken}})

            this.appointment = {
                client: data.client.name,
                id: this.id,
                client_id: data.client.id
            }
            
            if(!reload) this.unknown_products = data.unknown_products;
            
            this.skus = []

            if(this.unknown_products){
                data.products.data.forEach(x => {
                    x = x.attributes;

                    if(x.unknownProducts.boxes == 0 && x.unknownProducts.containers == 0 && x.unknownProducts.pallets > 0) {
                        this.unknown_product.type = "pallets";
                        this.unknown_product.quantity = x.unknownProducts.pallets;
                    }
                    else if(x.unknownProducts.pallets == 0 && x.unknownProducts.containers == 0 && x.unknownProducts.boxes > 0) {
                        this.unknown_product.type = "boxes";
                        this.unknown_product.quantity = x.unknownProducts.boxes;
                    }
                    else if(x.unknownProducts.pallets == 0 && x.unknownProducts.boxes == 0 && x.unknownProducts.containers > 0) {
                        this.unknown_product.type = "containers";
                        this.unknown_product.quantity = x.unknownProducts.containers;
                    }
                })

                this.$store.state.loaderProps.visible = false;
            }
            else {

                data.products.data.forEach(x => {
                    x = x.attributes;
                    if(!Object.hasOwn(x.unknownProducts,'boxes')){
                        this.skus.push({
                            id: x.product.id,
                            quantity: x.quantity,
                            sku: x.product.description,
                            actions: JSON.parse(JSON.stringify(this.actions)),
                            parameters: x.product.id
                        });
                    }  
                })

                this.$http.get(this.$store.state.chronosApi + 'getProducts/' + this.appointment.client_id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    response.data = response.data.data;
                    response.data.forEach(x =>{
                        x = x.attributes;
                        this.products.push({
                            product_id: x.id,
                            sku_p: x.sku,
                            sku: x.sku + ' | ' + x.description, 
                            id: x.id
                        });
                    
                    });
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
        },
        methodHandler(object){
            this[object.methodName](object.parameters);
        },
        save(){
            if(this.unknown_products) this.processUnknownProducts();
            else if(!this.unknown_products) this.processProducts();
        },
        processProducts(){
            if(this.skus.length == 0) {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('preload.no_products')
                }

                return;
            }

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            
            const payload = {
                products: []
            };

            this.skus.forEach(x => {
                payload.products.push({ product_id: x.id, quantity: x.quantity })
            })

            this.$http.post(this.$store.state.chronosApi + 'appointments/prefetch/' + this.id, payload, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('preload.responses.ok')
                    }
                    this.$router.go(-1);
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('preload.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        processUnknownProducts(){
            if(this.unknown_product.quantity == "" && this.unknown_product.type == "") {
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('preload.no_products')
                }

                return;
            }

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            
            const payload = {
                unknown_products: {
                    pallets: 0,
                    boxes: 0
                } 
            };

            if(this.unknown_product.type == "pallets") payload.unknown_products.pallets = parseInt(this.unknown_product.quantity);
            else if(this.unknown_product.type == "boxes") payload.unknown_products.boxes = parseInt(this.unknown_product.quantity);
            else if(this.unknown_product.type == "containers") payload.unknown_products.containers = parseInt(this.unknown_product.quantity);

            this.$http.post(this.$store.state.chronosApi + 'appointments/prefetch/' + this.id, payload, {headers: {Authorization: lsToken}})
            .then((response) => {
                if(response.status === 200){
                    this.snackbar = {
                        visible: true,
                        color: 'success2',
                        text: this.$t('preload.responses.ok')
                    }
                    
                    this.$router.go(-1);
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('preload.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        cancel(){
            this.$router.go(-1);
        },
        addProduct(){
            if(this.product.id == "" || this.product.id == null || this.product.quantity == "" || this.product.quantity == null ) return;

            if(this.skus.find(x => x.id == this.product.id)){
                this.snackbar = {
                    visible: true,
                    color: 'warning',
                    text: this.$t('preload.product_already_in_list') + " (" + this.products.find(x => x.id == this.product.id).sku +")"
                }  

                this.skus = [];

                return;      
            }

            this.skus.push(JSON.parse(JSON.stringify({
                id: this.product.id,
                quantity: this.product.quantity,
                sku: this.products.find(x => x.id == this.product.id).sku,
                actions: JSON.parse(JSON.stringify(this.actions)),
                parameters: this.product.id
            })))

            this.$refs.formsku.reset();
        },
        sum(product_id){
            this.product_to_sum = product_id

            this.modal_sum = true;
        },
        removeProduct(product_id){
            const index = this.skus.findIndex(x => x.id == product_id)
            if(index != null && index != undefined){
                this.skus.splice(index, 1)
            }
        },
        saveUpdateQuantity(){
            this.modal_sum = false;

            const index = this.skus.findIndex(x => x.id == this.product_to_sum)
            if(index != null && index != undefined){
                this.skus[index].quantity = parseInt(this.skus[index].quantity);
                this.skus[index].quantity += parseInt(this.to_sum);
                this.to_sum = "";
            }
        },
        addMultipleSkus(){
            if(this.file_products != []){
                this.createInput(this.file_products);
            }
        },
        createInput(file) {
            const promise = new Promise((resolve) => {
                const reader = new FileReader();
                // const vm = this;
                reader.onload = () => {
                    resolve((this.readFile = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                result => {
                    /* handle a successful result */
                    this.readRows = result.split('\r\n');
                    this.sTHeaders = this.readRows[0].split(',')
                    this.readRows.shift()
                    this.processSkus();
                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },
        async processSkus(){
            this.modal_import = false;
            for(var i = 0; i < this.readRows.length; i++){
                var p = this.readRows[i].split(",");
                var p_id = this.products.find(x => x.sku.split("|")[0].trim() == p[0]);

                if(p_id == undefined){
                    this.skus = [];
                    
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('store_product.product_not_found') + (i+1).toString()
                    }
                    break;
                }

                this.product = {
                    id: p_id.id,
                    quantity: parseInt(p[1])
                }

                await this.addProduct(true);
            }
        },
    },
    watch:{
        'unknown_products': function(){
            if(this.unknown_products == false){
                this.load_data(true);
            }
        }
    }
}
</script>

<style lang="css">
</style>