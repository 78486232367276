<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-0 size">
                <v-card-title class="justify-center">
                    <h2 class="text-uppercase azul--text">{{ $t('new_order.new_order') }}</h2>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <client-selector :addAllOption="false" class="py-0 my-0 " ref="clientSelector" @getClients="v => items.clients = v" :only_fulfillment="1"></client-selector>  
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.unique_order_number" :label="$t('new_order.uon')" @keyup="uppercase" :rules="rules_uon" counter="20"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-switch v-model="external_guide" color="azul">
                                    <template v-slot:label>
                                        {{$t("new_order.external_guide")}}
                                    </template>
                                </v-switch>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="external_guide == true">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.shipment_number" :label="$t('new_order.shipment_number')" @keyup="uppercase" :rules="rules_required" ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="external_guide == true">
                            <v-col cols="6" justify="center" align="center">
                                <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" :clearable="true" v-model="form.file" accept=".png" :label="$t('new_order.guide_file')"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="external_guide == true">
                            <v-col cols="6" justify="center" align="center">
                                <v-file-input prepend-icon="" append-outer-icon="cloud_upload" class="cloud_upload py-0" multiple :clearable="true" v-model="form.attached_files" accept=".pdf" :label="$t('new_order.attached_files')"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" >
                            <v-col cols="6" justify="center" align="center">
                                <v-autocomplete v-model="form.consignee.country" :label="$t('new_order.country')" :rules="rules_required" :items="countries"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="external_guide == false">
                            <v-col cols="6" justify="center" align="center">
                                <v-autocomplete v-model="form.shipping_service" :disabled="external_guide" item-text="text" item-value="value" :label="$t('new_order.shipping_service')" :rules="rules_required" :items="services"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="external_guide == true">
                            <v-col cols="6" justify="center" align="center">
                                <v-autocomplete v-model="form.external_shipping_service" :disabled="!external_guide" item-text="text" item-value="value" :label="$t('new_order.shipping_service')" :rules="rules_required" :items="external_services"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-autocomplete v-model="cod" item-text="text" item-value="value" :label="$t('new_order.order_type')" :rules="rules_required" :items="cod_types"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.name" :label="$t('new_order.full_name')" :rules="consignee_name" counter="35"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.phone" :label="$t('new_order.phone')" counter="10" :rules="consignee_phone"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.email" :label="$t('new_order.email')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.zipcode" counter="5" :label="$t('new_order.zip_code')" :rules="consignee_zipcode"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="form.consignee.country == 'México'">
                            <v-col cols="6" justify="center" align="center">
                                <v-autocomplete v-model="form.consignee.neighborhood" :label="$t('new_order.neigborhood')" :rules="rules_required" :items="neighborhoods"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="form.consignee.country == 'México'">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.street" :label="$t('new_order.street')" counter="24" :rules="consignee_street_mexico"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-else>
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.street" :label="$t('new_order.street')" counter="35" :rules="consignee_street_usa"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="form.consignee.country == 'México'">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.external_number" :label="$t('new_order.external_number')" counter="5" :rules="consignee_external_number"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center"  v-if="form.consignee.country == 'México'">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.internal_number" :label="$t('new_order.internal_number')" :rules="consignee_internal_number" counter="5"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="form.consignee.country == 'México'">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.municipality" :label="$t('new_order.municipality')" :rules="rules_required" disabled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="form.consignee.country == 'México'">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.state" :label="$t('new_order.state')" :rules="rules_required" disabled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="form.consignee.country != 'México'">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.municipality" :label="$t('new_order.municipality')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="form.consignee.country != 'México'">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.state" :label="$t('new_order.state')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-col cols="6" justify="center" align="center">
                                <v-text-field v-model="form.consignee.comments" :label="$t('new_order.address_reference')"  counter="255"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-text class="pt-0">
                    <v-form ref="productForm" lazy-validation @submit.prevent="save">
                        <v-row align="center" justify="center">
                            <v-col class="py-0" cols="6" justify="center" align="center">
                                <v-text-field v-model="form.total_price" :label="$t('new_order.total_cost')" :rules="cod == 'prepaid' ? r_total_price : []"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row align="center" justify="center">
                        <v-col cols="4" justify="center" align="center">
                            <v-autocomplete :items="items" v-model="product" item-value="product_id" item-text="sku" :label="$t('new_order.product')"></v-autocomplete>
                        </v-col>
                        <v-col cols="2" justify="end" align="end">
                            <v-btn color="amarillo" class="white--text" @click="addProduct">{{ $t('new_order.add') }}</v-btn>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center" v-for="(order, index) in form.order_details" :key="index">
                        <v-col cols="3" justify="start" align="start">
                            {{ order.sku }}
                        </v-col>
                        <v-col cols="2" justify="center" align="center">
                            <v-text-field hide-details :label="$t('new_order.quantity')" v-model="order.quantity" type="number"></v-text-field>
                        </v-col>
                        <v-col cols="1" justify="end" align="end">
                            <v-btn color="red" icon small @click="removeProduct(index)"><v-icon>remove_circle_outline</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text>
                    <v-row align="center" justify="center">
                        <v-col cols="6">
                            <v-row justify="end" align="center">
                            <v-btn class="mr-2" text @click="cancel">{{ $t('new_order.cancel') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="save">{{ $t('new_order.create_order') }}</v-btn>
                        </v-row>
                        <v-row justify="end" align="center">
                            <v-checkbox v-model="keepPage" dense :label="$t('new_order.keep_here')"></v-checkbox>
                        </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    computed:{
        rules_uon(){
            return [
                v => !!v || this.$t('new_order.validations.required_field'),
                v => v.toString().length >= 6 && v.toString().length <= 20 || this.$t('new_order.validations.uon'),
            ];
        },       
        rules_tn() {
            return [
                v => !!v || this.$t('new_order.validations.required_field'),
            ]
        },       
        consignee_name() {
            return [
                v => !!v || this.$t('new_order.validations.required_field'),
                v => v.toString().length >= 3 && v.toString().length <= 35 || this.$t('new_order.validations.name'),
            ];
        },
        consignee_phone() {
            return [
                v => v.toString().length === 10 || this.$t('new_order.validations.phone'),
                v => /^(\d*\.)?\d+$/.test(v) || this.$t('new_order.validations.numbers'),
            ]
        },
        consignee_street_mexico(){
            return [
                v => !!v || this.$t('new_order.validations.required_field'),
                v => v.toString().length <= 24 || this.$t('new_order.validations.street_mx'),
            ];
        }, 
        consignee_street_usa(){
            return [
                v => !!v || this.$t('new_order.validations.required_field'),
                v => v.toString().length <= 35 || this.$t('new_order.validations.street_usa'),
            ];
        }, 
        consignee_external_number(){
            return [
                v => !!v || this.$t('new_order.validations.required_field'),
                v => v.length <= 5 || this.$t('new_order.validations.external_number'),
            ];
        },
        consignee_internal_number(){
            return [
                v => v.toString().length <= 5 || this.$t('new_order.validations.internal_number'),
            ];
        },
        consignee_zipcode(){
            return [
                v => v.toString().length === 5 || this.$t('new_order.validations.zip_code'),
                v => /^(\d*\.)?\d+$/.test(v) || this.$t('new_order.validations.numbers'),
            ];
        },
        consignee_comments(){ 
            return [
                v => (v.toString().length == 0 || (v.toString().length >= 3 && v.toString().length <= 255)) || this.$t('new_order.validations.comments'),
            ]
        }, 
        rules_required(){return [v => !!v || this.$t('new_order.validations.required_field')]}, 
        rules_email(){
            return [v => (v.length == 0 || /.+@.+\..+/.test(v)) || this.$t('new_order.validations.email')];
        },
        r_total_price(){
            return [
                v => !!v || this.$t('new_order.validations.total'),
                v => /^(\d*\.)?\d+$/.test(v) || this.$t('new_order.validations.numbers'),
                v => parseFloat(v) <= 8000 || this.$t('new_order.validations.total_limit'),
            ];
        } 
    },
    data(){
        return {
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            loadingData: false,
            keepPage: false,
            cod: 'prepaid',
            token: '',
            neighborhoods:[],
            neighborhoodsData: [],
            external_guide: false,
            form: {
                date: "",
                cod: false,
                unique_order_number: "",
                shipping_service: "FEDEX",
                external_shipping_service: "",
                shipment_number: "",
                file: [],
                attached_files: [],
                invoice: "",
                warehouse_id: this.$store.state.warehouseId,
                consignee: {
                    name: "",
                    country: "México",
                    state: "",
                    municipality:"",
                    neighborhood: "",
                    street: "",
                    external_number: "",
                    internal_number: "",
                    zipcode: "",
                    email: '',
                    phone: "",
                    comments: "",
                },
                order_details: [],
                total_price: ""
            },
            product: {
                quantity: '',
                sku: '',
                price: ''
            },
            cod_types: [{value: "prepaid", text: this.$t("new_order.prepaid")}, {value: "cod", text: this.$t("new_order.cod")}],
            items: [],
            products: [],
            clientId: "",
            client: "",
            bearer: "",
            services: this.$store.state.shipping_services,
            external_services: [
                "FedEx", 
                "UPS", 
                "DHL", 
                "PaqueteExpress", 
                "99 Minutos", 
                "Estafeta", 
                "Quiken", 
                "AFIMEX", 
                "Mercado Colecta", 
                "Tres Guerras",
                "Redpack",
                "Pickup",
                "J&T",
                "Afimex"
            ],
            countries: ["México", "United States"]
        }
    },
    mounted(){
        this.$store.state.module = "headers.create_order";
    },
    methods: {
        index(){
            this.getProducts();
            this.getClientToken();
            this.calcDates();
        },
        getProducts(){
            const lsToken = localStorage.getItem("access_token");
            this.items = [];
            this.$http.get(this.$store.state.chronosApi + 'products/customer/' + this.clientId, { headers: {Authorization: lsToken} })
            .then((response) => {
                response.data.products.data.forEach(x => {
                    x = x.attributes;
                    this.items.push({
                        sku: x.sku,
                        id: x.id
                    });
                })

                response.data.kits.forEach(x => {
                    this.items.push({
                        sku: x.sku,
                        id: x.id
                    })
                })
            })
            .catch((error) => {
                error
            })
        },
        addProduct(){
            if(this.product !== ''){
                const find = this.form.order_details.findIndex(x => x.sku === this.product);
                const product = this.items.find( x => x.sku == this.product);

                if(find < 0 && product != undefined && product != null){
                    this.form.order_details.push({
                        quantity: 1,
                        sku: product.sku,
                        product_id: product.id,
                        unit_price: 1
                    })
                }
            }
        },
        getClientToken(){
            if(this.clientId != ""){
                this.$http.get(this.$store.state.chronosApi + 'getClientToken/'+ this.clientId)
                .then(response => {
                    this.client = response.data.client

                    this.$http.get(this.$store.state.chronosApi + 'getCurrenAvailableToken/' + this.clientId)
                    .then(response2 => {
                        if(response2.data.token != "---"){
                            this.bearer = "Bearer " + response2.data.token;
                        }
                        else if(response2.data.token == "---"){
                            if(this.client != null && this.client.token != ""){
                                this.$http.post(this.$store.state.chronosApi + 'token/generate', {email: "alejandro@intech.mx", password: "afuerzaniloscalcetines:" + this.client.token}, {headers:{}})
                                // this.$http.post(this.$store.state.chronosApi + 'token/generate', {email: "derkk117@gmail.com", password: "afuerzaniloscalcetines:" + this.client.token}, {headers:{}})
                                .then( response3 => {
                                    this.bearer = "Bearer " + response3.data.token;
                                })
                            }
                        }
                    })
                })
            } else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: "Can't found client token..."
                }
            }
        },
        removeProduct(index){
            this.form.order_details.splice(index, 1)
        },
        calcDates(){
            const date = new Date()
            
            this.form.date = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, 0) + '-' + date.getDate().toString().padStart(2, 0)
        },
        save(){
            if(this.bearer != ""){
                const formVal = this.$refs.form.validate()
                const prodFormVal = this.$refs.productForm.validate();
                if(formVal && prodFormVal){
                    if(this.external_guide == true) this.saveWithGuide();
                    else this.saveWithoutGuide();
                }else{
                    this.$store.state.loaderProps.visible = false
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: 'Form incomplete'
                    }
                }
            }
            else {
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: 'Error getting client data'
                }
            }
        },
        saveWithoutGuide(){
            this.$store.state.loaderProps.visible = true;
            if(this.form.total_price == 0 || this.form.total_price.toString().trim() == "") this.form.total_price = null;
            delete this.form.external_shipping_service;
            if(this.form.order_details.length > 0){
                let count = 0;
                this.form.order_details.forEach(x =>{
                    count += parseInt(x.quantity);
                })
                this.form.order_details.forEach(x =>{
                    x.unit_price = this.form.total_price / count;
                })
                this.form.consignee.neighborhood = this.form.consignee.neighborhood.substring(0,35);
                this.form.cod = (this.cod == "prepaid" ? false : true);
                this.$http.post(this.$store.state.chronosApi+'shipment/create', this.form, { headers: { Authorization: this.bearer } })
                .then((response) => {
                    if(response.status === 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: 'Orden creada con éxito'
                        }

                        if(this.keepPage){
                            this.$refs.form.reset()
                            this.total_price = '';
                            this.form.order_details = []
                        }else{
                            this.cancel();
                        }
                    }
                    this.$store.state.loaderProps.visible = false;
                })
                .catch((error) => {
                    var errors = ''
                
                    if((typeof error.response.data.message) != 'string'){
                        for (var key in error.response.data.message) {
                            errors += error.response.data.message[key][0]+',';
                        }
                        errors = errors.replace(/,(?=\s*$)/, '');
                    }else
                        errors = error.response.data.message;
                        
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: errors
                    }
                })
            }else{
                this.$store.state.loaderProps.visible = false
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: 'Add at least 1 product'
                }
            }
        },
        saveWithGuide(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token")
            
            if(this.form.total_price == 0 || this.form.total_price.toString().trim() == "") this.form.total_price = null;
            if(this.form.file.length == 0 && this.form.attached_files.length == 0){
                this.$store.state.loaderProps.visible = false
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t("new_order.add_some_file")
                }
                return;
            }

            if(this.form.order_details.length > 0){
                let count = 0;
                this.form.order_details.forEach(x =>{
                    count += parseInt(x.quantity);
                })
                this.form.order_details.forEach(x =>{
                    delete x.product_id;
                    x.unit_price = this.form.total_price / count;
                })
                this.form.consignee.neighborhood = this.form.consignee.neighborhood.substring(0,35);
                this.form.cod = (this.cod == "prepaid" ? false : true);

                const formData = new FormData();
                formData.append('date', this.form.date);
                formData.append('cod', this.form.cod);
                formData.append('unique_order_number', this.form.unique_order_number);
                formData.append('shipping_service', this.form.external_shipping_service);
                formData.append('shipment_number', this.form.shipment_number);

                // Optional files
                if(this.form.file.length > 0) formData.append('label', this.form.file);
                if(this.form.attached_files.length > 0)
                    for(var counter = 0; counter < this.form.attached_files.length; counter++){
                        formData.append('attached_files[]', this.form.attached_files[counter]);
                    }

                formData.append('invoice', this.form.invoice);
                formData.append('warehouse_id', this.form.warehouse_id);
                formData.append('total_price', this.form.total_price);
                formData.append('order_details', JSON.stringify(this.form.order_details));

                formData.append('consignee_name', this.form.consignee.name);
                formData.append('consignee_country', this.form.consignee.country);
                formData.append('consignee_state', this.form.consignee.state);
                formData.append('consignee_municipality', this.form.consignee.municipality);
                formData.append('consignee_neighborhood', this.form.consignee.neighborhood);
                formData.append('consignee_street', this.form.consignee.street);
                formData.append('consignee_external_number', this.form.consignee.external_number);
                formData.append('consignee_internal_number', this.form.consignee.internal_number);
                formData.append('consignee_zipcode', this.form.consignee.zipcode);
                formData.append('consignee_email', this.form.consignee.email);
                formData.append('consignee_phone', this.form.consignee.phone);
                formData.append('consignee_comments', this.form.consignee.comments);

                // console.log([...formData]) // Print form data displaying all pr

                this.$http.post(this.$store.state.chronosApi+'shipment/create_with_label', formData, { headers: { Authorization: this.bearer, 'Content-Type': 'multipart/form-data' } })
                .then((response) => {
                    if(response.status === 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: 'Orden creada con éxito'
                        }

                        if(this.keepPage){
                            this.$refs.form.reset()
                            this.total_price = '';
                            this.form.order_details = []
                        }else{
                            this.cancel();
                        }
                    }
                    this.$store.state.loaderProps.visible = false;
                })
                .catch((error) => {
                    var errors = ''
                
                    if((typeof error.response.data.message) != 'string'){
                        for (var key in error.response.data.message) {
                            errors += error.response.data.message[key][0]+',';
                        }
                        errors = errors.replace(/,(?=\s*$)/, '');
                    }else
                        errors = error.response.data.message;
                        
                    this.$store.state.loaderProps.visible = false;
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: errors
                    }
                })
            }else{
                this.$store.state.loaderProps.visible = false
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: 'Add at least 1 product'
                }
            }
        },
        uppercase() {
            if(this.form.unique_order_number != null){
                this.form.unique_order_number = this.form.unique_order_number.toUpperCase();
                this.form.unique_order_number = this.form.unique_order_number.replace(/\s/g,'');
            }

            if(this.form.shipment_number != null){
                this.form.shipment_number = this.form.shipment_number.toUpperCase();
                this.form.shipment_number = this.form.shipment_number.replace(/\s/g,'');
            }
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch: {
        '$store.state.clientId': function() {
            this.clientId = this.$store.state.clientId;
            this.index();
        },
        'form.consignee.zipcode': function(){
            this.neighborhoods = [];
            this.form.consignee.state = '';
            this.form.consignee.municipality = '';
            if(this.form.consignee.zipcode.length == 5){
                this.$http.get('https://locations.intech.mx/api/neighborhoods/zip_code/' + this.form.consignee.zipcode).then(response =>{
                    response.data.forEach(x => {
                        this.neighborhoods.push(x.name);
                        this.neighborhoodsData.push(x);
                    })
                })
            }
        },
        'form.consignee.neighborhood': function(){
            this.neighborhoodsData.forEach(x =>{
                if(x.name == this.form.consignee.neighborhood){
                    this.$http.get("https://locations.intech.mx/api/getCityDetails/" + x.city_id).then(response =>{
                        this.form.consignee.municipality = response.data.city.name;
                        this.form.consignee.state = response.data.state.alt_names[0];
                    });
                }
            })
        },
        city_model(value){
            this.municipality_model = ''
            const selected = this.cities.find(x => x.value === value)
            this.form.consignee.state = selected.text

            this.getMunicipalities()
        },
        municipality_model(value){
            const selected = this.municipalities.find(x => x.value === value)
            this.form.consignee.municipality = typeof selected !== 'undefined' ? selected.text : ''
        },
        external_guide: function(){
            if(this.external_guide == true) this.form.shipping_service = "OTHER";
            else this.form.shipping_service = "FEDEX";
        },
        'form.shipping_service': function(){
            if(this.form.shipping_service == "OTHER") this.external_guide = true;
            else this.external_guide = false;
        }
    }
}
</script>

<style>

</style>