<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="mx-3 pt-4 pb-2" >
                <v-col sm="6" md="2" class="py-0 my-0">
                    <client-selector :addAllOption="false" class="py-0 mb-1" ref="clientSelector" @getClients="v => items.clients = v" :is_national="2"></client-selector>  
                </v-col>
                <v-col sm="6" md="2" class="py-0 my-0">
                    <v-autocomplete class="py-0 mb-1" item-value="value" item-text="name" v-model="search.criterion" :items="criterions" :label="$t('search.criterion')"></v-autocomplete> 
                </v-col>
                <v-col sm="6" md="2" class="py-0 my-0">
                    <v-text-field class="py-0 mb-1" v-model="search.value" :label="$t('search.data_to_search')"></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="6" align="end" class="py-0" justify="center">
                    <v-btn class="right white--text mr-2" color="amarillo" @click="searchOrder">
                        {{ $t('search.search') }}                    
                        <v-icon class="px-1" small>search</v-icon>
                    </v-btn>
                    <v-btn class="right white--text" color="amarillo" @click="advancedSearchModal = true; showAdvancedSearch = true;">
                        {{ $t('search.advanced_search') }}
                        <v-icon class="px-1" small>zoom_in</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="size_buttons" v-if="showAdvancedSearch == false">
                <v-row class="px-0 pt-6 mx-0" align="center" justify="center">
                    <v-col cols="6">
                        <v-card class="ml-5 mr-1 middle_size_left_card">
                            <v-row class="ml-5 mr-1 py-0 my-2" justify="center" align="center">
                                <v-col cols="12" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('search.general_data')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.order')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.shipment.unique_order_number}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.warehouse')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;" v-if="search.criterion == 'fulfillment_eon'">{{order.w_name}}</label>
                                    <label style="font-size: 16px;" v-if="search.criterion != 'fulfillment_eon'">{{order.warehouse.name}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.shipping_service')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.shipment.shipping_service}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.zone')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.shipment.zone}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.shipment_number')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.shipment.shipment_number}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.buyer')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.name}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.state')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.state}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.municipality')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.municipality}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.neighborhood')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.neighborhood}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.address')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.street}}, {{order.consignee.external_number}} {{order.consignee.internal_number}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.zipcode')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.zipcode}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.phone')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.phone}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.references')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.consignee.comments}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.ext_zone')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.shipment.ext_zone}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.packing')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.shipment.sizes}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.weight')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.shipment.weight}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.order_type')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;" v-if="order.shipment.cod == true">{{$t('search.is_cod')}}</label>
                                    <label style="font-size: 16px;" v-if="order.shipment.cod == false">{{$t('search.is_prepaid')}}</label>
                                    <label style="font-size: 16px;" v-if="search.criterion == 'fulfillment_eon'">{{$t('search.is_prepaid')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center" v-if="order.minutes != null">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.minutes_status')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.minutes.status}} - ({{ order.minutes.date }})</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center" v-if="order.minutes != null">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.minutes_comments')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.minutes.comments}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.total_cod')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 16px;">{{order.shipment.total}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 ml-5 mr-1 py-0 my-0" justify="end" align="end">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;">{{$t('search.products')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0" v-for="product of order.products" :key="product.id">
                                    <label style="font-size: 16px;">{{ product.quantity }} - ({{ product.sku }}) - {{ product.description }}</label>
                                </v-col>
                                <v-col cols="7" v-if="order.products.length == 0" class="py-0">
                                    <label style="font-size: 16px;"></label>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card class="ml-5 mr-1 mt-5 size_right_bottom_card">
                            <v-row class="px-0 mr-0 ml-5 py-0 my-2" justify="center" align="center">
                                <v-col cols="12" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('search.payment_details')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="order.shipment.paid_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.shipping_service_fee')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;" v-if="order.shipment.paid_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_at.split('T')[0] }}</label>
                                    <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="order.shipment.paid_fulfillment_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.fulfillment_fee')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;" v-if="order.shipment.paid_fulfillment_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_fulfillment_at.split('T')[0] }}</label>
                                    <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="order.shipment.paid_label_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.label_fee')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;" v-if="order.shipment.paid_label_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_label_at.split('T')[0] }}</label>
                                    <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="order.shipment.paid_extzone_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.ext_zone_fee')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;" v-if="order.shipment.paid_extzone_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_extzone_at.split('T')[0] }}</label>
                                    <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="order.shipment.paid_shipment_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.shipping_fee')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;" v-if="order.shipment.paid_shipment_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_shipment_at.split('T')[0] }}</label>
                                    <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="order.shipment.paid_cod_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.cod_fee')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;" v-if="order.shipment.paid_cod_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_cod_at.split('T')[0] }}</label>
                                    <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="order.shipment.paid_return_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.return_fee')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;" v-if="order.shipment.paid_return_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_return_at.split('T')[0] }}</label>
                                    <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 mt-1 mb-5" v-bind:class="order.shipment.paid_address_update_at != null ? 'rounded-green':'rounded-red'" justify="center" align="center">
                                <v-col cols="5" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.address_correction_fee')}}</label>
                                </v-col>
                                <v-col cols="7" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;" v-if="order.shipment.paid_address_update_at != null">{{$t('search.paid_at')}} {{ order.shipment.paid_address_update_at.split('T')[0] }}</label>
                                    <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>

                    <v-col cols="6">
                        <v-card class="mr-5 ml-1 middle_size_left_card">
                            <v-row class="px-0 mr-0 ml-5 py-0 my-2" justify="center" align="center">
                                <v-col cols="12" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('search.delivery_details')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" justify="center" align="center">
                                <v-col cols="6" class="py-0" justify="start" align="start">
                                    <label>{{ $t('search.status')}}</label>
                                </v-col>
                                <v-col cols="6" class="py-0" justify="end" align="end">
                                    <label>{{ $t('search.date')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-7 ml-7 py-0 my-1" v-for="status of order.statuses" :key="status.id" style="border-bottom: 1px solid #444444; border-radius: 0px;" justify="center" align="center">
                                <v-col cols="6" class="py-0" justify="start" align="start">
                                    <label style="font-size: 14px;"> {{status.status}} </label>
                                </v-col>
                                <v-col cols="6" class="py-0" justify="end" align="end">
                                    <label style="font-size: 14px;" v-if="status.date == null"> {{ status.created_at.includes("T")? status.created_at.split('T')[0]: status.created_at.split(' ')[0] }} {{ status.created_at.includes("T")? status.created_at.split('T')[1].substring(0, 5) : status.created_at.split(' ')[1].substring(0, 5) }} </label>
                                    <label style="font-size: 14px;" v-else> {{ status.date }} </label>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-card class="mr-5 ml-1 mt-5 size_right_bottom_card">
                            <v-row class="px-0 mr-0 ml-5 py-0 my-2" justify="center" align="center">
                                <v-col cols="12" class="py-0">
                                    <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('search.invoice_details')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="'rounded-green'" justify="center" align="center">
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.cost_date')}}</label>
                                </v-col>
                                <v-col cols="5" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;" v-if="order.charge.cost_date != null">{{$t('search.paid_at')}} {{ order.charge.cost_date }}</label>
                                    <label style="font-size: 14px;" v-else>{{$t('search.pending')}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="'rounded-green'" justify="center" align="center">
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.cost_total')}}</label>
                                </v-col>
                                <v-col cols="5" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;"> $ {{ order.charge.cost_total ?? 0.0 }}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="'rounded-green'" justify="center" align="center">
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.cost_ext_zone')}}</label>
                                </v-col>
                                <v-col cols="5" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;"> $ {{ order.charge.cost_ext_zone ?? 0.0 }}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="'rounded-green'" justify="center" align="center">
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.cost_fuel')}}</label>
                                </v-col>
                                <v-col cols="5" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;"> $ {{ order.charge.cost_fuel ?? 0.0 }}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="'rounded-green'" justify="center" align="center">
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.cost_sign')}}</label>
                                </v-col>
                                <v-col cols="5" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;"> $ {{ order.charge.cost_sign ?? 0.0 }}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="'rounded-green'" justify="center" align="center">
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.cost_dimensions')}}</label>
                                </v-col>
                                <v-col cols="5" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;"> $ {{ order.charge.cost_dimensions ?? 0.0 }}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="'rounded-green'" justify="center" align="center">
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.cost_correction')}}</label>
                                </v-col>
                                <v-col cols="5" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;"> $ {{ order.charge.cost_correction ?? 0.0 }}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="'rounded-green'" justify="center" align="center">
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.cost_iva')}}</label>
                                </v-col>
                                <v-col cols="5" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;"> $ {{ order.charge.cost_iva ?? 0.0}}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="'rounded-green'" justify="center" align="center">
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.billing_returned_date')}}</label>
                                </v-col>
                                <v-col cols="5" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;"> {{ order.charge.billing_returned_date }}</label>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 mr-5 ml-5 py-0 my-1" v-bind:class="'rounded-green'" justify="center" align="center">
                                <v-col cols="7" class="py-0">
                                    <label style="font-size: 14px;">{{$t('search.billing_returned_cost')}}</label>
                                </v-col>
                                <v-col cols="5" class="py-0" justify="center" align="end">
                                    <label style="font-size: 14px;"> $ {{ order.charge.billing_returned_cost ?? 0.0 }}</label>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-card class="mt-1 pt-1 pb-10 px-0 size_buttons" style="margin-bottom: 35px !important" v-if="showAdvancedSearch == true">     
                <v-row>
                    <label for="" class="mt-5 mb-2 mx-8 px-2 pt-0 azul--text" style="font-size: 14px; font-weight: bold;">{{this.advancedOrders.length}} {{$t('search.results_found')}}</label>
                </v-row>
                <span v-for="data in this.advancedOrders" :key="data.id">
                    <v-col cols="6" class="px-0 py-0" style="display:inline-block !important">
                        <v-card class="mx-2 mt-4 px-3 py-2" @click="searchOrderFromAdvanced(data.unique_order_number)" style="font-size:13px;" cols="6">
                            <v-col class="px-2 mx-0" cols="12" md="12" align="start" >
                                <v-row >
                                    <v-col cols="12" class="px-4 mx-0">
                                        <v-row>
                                            <span class="azul--text font-weight-bold text-uppercase">{{ $t('search.order') }}:  </span> {{ data.unique_order_number }}
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6" class="px-4 mx-0">

                                        <v-row>
                                            <span class="pr-1 accent--text font-weight-bold">{{ $t('search.shipping_service') }} </span> {{ data.shipping_service }}
                                        </v-row>
                                        <v-row>
                                            <span class="pr-1 accent--text font-weight-bold">{{ $t('search.buyer') }} </span> {{ data.name }}
                                        </v-row>
                                        <v-row>
                                            <span class="pr-1 accent--text font-weight-bold">{{ $t('search.state') }} </span> {{ data.state }}
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6" class="px-4 mx-0">
                                        <v-row>
                                            <span class="pr-1 accent--text font-weight-bold"> {{ $t('search.phone') }}: </span> {{ data.phone }}
                                        </v-row>
                                        <v-row>
                                            <span class="pr-1 accent--text font-weight-bold">{{ $t('search.zipcode') }}: </span><span >{{ data.zipcode }}</span>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-card>
                    </v-col>
                </span>
            </v-card>
            <v-dialog v-model="advancedSearchModal" persistent max-width="90%">
                <v-card style="width:600px">
                    <v-card-title class="justify-center mt-4">
                        <v-row align="center" justify="center" class="my-2">
                            <label style="font-size: 16px; font-weight: bold;" class="azul--text text-uppercase">{{$t('search.advanced_search')}}</label>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="12" class="py-0">
                                <client-selector :addAllOption="false" class="py-0 mb-1" ref="clientSelector" @getClients="v => items.clients = v" :is_national="2"></client-selector>  
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="12" class="py-0">
                                <v-text-field class="py-0 my-0" v-model="advanced_search.consignee" :label="$t('search.select_buyer')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="12" class="py-0">
                                <v-text-field class="py-0 my-0" v-model="advanced_search.phone" :label="$t('search.phone')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="12" class="py-0">
                                <v-text-field class="py-0 my-0" v-model="advanced_search.zipcode" :label="$t('search.zipcode')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="12" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="advanced_search.state_short" :items="states" :label="$t('create_customer.state')"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="py-0 my-0 text-center">
                            <v-col cols="12" class="py-0">
                                <v-autocomplete class="mt-0 py-0" v-model="advanced_search.municipality" :items="municipalities" :label="$t('create_customer.municipality')"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="advancedSearchModal = false; showAdvancedSearch = false">{{ $t('search.cancel') }}</v-btn>
                            <v-btn color="amarillo" class="white--text" @click="advancedSearch"> 
                                {{ $t('search.search') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
        </v-container>   
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>

<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
        client: function(){
            return this.$store.state.clientId
        }
    },
    data(){
        return {
            numberFormat: new Intl.NumberFormat('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            search: {
                value: '',
                client_id: '',
                criterion: '',
            },
            advanced_search: {
                consignee: '',
                phone: '',
                zipcode: '',
                state: '',
                state_short: '',
                municipality: '',
                client_id: ''
            },
            order: {
                statuses: {},
                shipment: {},
                consignee: {},
                products: [],
                minutes: [],
                charge: {},
                warehouse: {}
            },
            advancedOrders: [],
            states: [],
            municipalities: [],
            value: true,
            advancedSearchModal: false,
            showAdvancedSearch: false,
            criterions: [
                { name: this.$t("search.tracking_number"), value: "tn" }, 
                { name: this.$t("search.uon"), value: "uon" },
                { name: this.$t("search.fulfillment_eon"), value: "fulfillment_eon" }
            ]
        }
    },
    mounted(){
        this.$store.state.clientId = null;
        this.index();
        this.$store.state.module = "headers.search";
    },
    methods: {
        methodHandler(object){
            this[object.methodName](object.parameters)
        },
        index(){
            this.states = [];
            this.states_load = true;
            this.$http.get("https://locations.intech.mx/api/getStates/MX", {})
            .then((response) => {
                if(response.status === 200){
                    this.states = response.data
                    this.states_load = false;
                }
            }).finally(() => {
                this.states_load = false;
            });
        },
        searchOrder(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'shipment/shipmentSearch/' + this.search.client_id + '/' + this.search.criterion + '/' + this.search.value, { headers: { Authorization: lsToken } })
            .then((response) => {
                if(this.search.criterion != "fulfillment_eon"){
                    if(response.data.success === true){
                        this.$store.state.loaderProps.visible = false;
                        this.order.statuses = response.data.statuses;
                        this.order.shipment = response.data.shipment;
                        this.order.minutes = response.data.minutes;
                        this.order.charge = response.data.charge;

                        var now = new Date();
                        this.order.statuses.forEach(x => {
                            if(x.status == "READY"){
                                var ready_date = new Date(x.created_at.split('T')[0].replace( /(\d{4})-(\d{2})-(\d{2})/, "$3/$1/$2")).getTime() + (90 * 24 * 60 * 60 * 1000)
                                if (now > ready_date) this.can_download_label = false;
                            }
                            else if(x.status == "RETURNED TO CHRONOS") this.can_mark_damaged = true;
                        })
                        
                        this.order.shipment.ext_zone = response.data.extZone == false ? "No Aplica" : "Aplica";

                        if(response.data.shipment.width == null || response.data.shipment.height == null || response.data.shipment.length == null) this.order.shipment.sizes = "";
                        else this.order.shipment.sizes = response.data.shipment.width + "x" + response.data.shipment.height + "x" + response.data.shipment.length;

                        if(parseFloat(this.order.shipment.b_volumetric_weight) > parseFloat(this.order.shipment.products_weight)) this.order.shipment.weight = this.order.shipment.b_volumetric_weight.toString() + "KG";
                        else if(parseFloat(this.order.shipment.products_weight) > parseFloat(this.order.shipment.b_volumetric_weight)) this.order.shipment.weight = this.order.shipment.products_weight.toString() + "KG";
                        else this.order.shipment.weight = this.order.shipment.products_weight.toString() + "KG";

                        this.order.shipment.total = '$' + this.numberFormat.format(response.data.shipment.total);
                        this.order.consignee = response.data.consignee;
                        this.order.products = response.data.products;
                    }
                } else {
                    this.order.shipment.unique_order_number = response.data.shipment.unique_order_number;
                    this.order.shipment.shipment_number = response.data.shipment.tracking_number;
                    this.order.shipment.shipping_service = response.data.shipment.shipping_service;
                    this.order.shipment.sizes = response.data.shipment.name;
                    this.order.consignee.name = response.data.shipment.consignee_name;
                
                    this.order.statuses = JSON.parse(response.data.shipment.status);
                    this.order.products = JSON.parse(response.data.shipment.products).products;
                    this.order.warehouse = response.data.warehouse;
                }
            })
            .catch((response) => {
                console.log(response)
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        advancedSearch(){
            this.$store.state.loaderProps.visible = true;
            this.advancedOrders = [];
            const lsToken = localStorage.getItem("access_token");
            this.$http.post(this.$store.state.chronosApi + 'shipment/advancedSearch', this.advanced_search, { headers: { Authorization: lsToken } })
            .then((response) => {
                if(response.data.success === true){
                    this.$store.state.loaderProps.visible = false;
                    
                    this.advancedOrders = response.data.shipments;
                    this.advancedSearchModal = false;
                }
            })
            .catch((response) => {
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            });
        },
        searchOrderFromAdvanced(uon){
            this.$store.state.loaderProps.visible = true;
            this.showAdvancedSearch = false;
            this.search.criterion = "uon";
            this.search.value = uon;

            this.searchOrder();
        }
    },
    watch: {
        '$store.state.clientId': function (){
            this.search.client_id = this.$store.state.clientId
            this.advanced_search.client_id = this.$store.state.clientId
        },
        "advanced_search.state_short": function(){
            this.advanced_search.state = this.states.find( x => x.value == this.advanced_search.state_short).text;
            this.municipalities_load = true;
            this.$http.get("https://locations.intech.mx/api/getCities/MX/" + this.advanced_search.state_short, {})
            .then((response) => {
                if(response.status === 200){
                    response.data.forEach(x => {
                        this.municipalities.push(x.text);
                    }) 
                    this.municipalities_load = false;
                }
            }).finally(() => {
                this.municipalities_load = false;
            });
        }
    }
}
</script>
<style>
</style>