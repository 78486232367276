<template>
    <div class="px-0">
        <v-container class="px-0" style="max-width: 100% !important">
            <v-row class="px-3 pb-2 mt-1" >
                <v-col cols="3" class="py-0">
                    <v-text-field
                        v-model="props.search"
                        append-icon="search"
                        :label="$t('fulfillment_eon_in_process.search')"
                        single-line
                        hide-details
                        class="mb-4 py-0 px-2 mt-0 tools-view-height"
                        style="background-color: white; border: 1px solid black; border-radius:5px"
                    ></v-text-field>
                </v-col>
                <v-spacer></v-spacer> 
                <v-col cols="9" align="end" class="py-0 px-0">
                    <v-row justify="end" align="end" class="mr-2 mt-0">
                        <v-btn class="right white--text tools-view-height mx-1" color="amarillo" style="font-size:11px" @click="exportShipments">
                            {{ $t('fulfillments_prepaid_in_process.export') }}
                            <!-- <v-icon class="material-icons-outlined px-1" small>downloading</v-icon> -->
                        </v-btn>
                        <v-btn class="right white--text tools-view-height mx-1" color="amarillo" style="font-size:11px" @click="downloadLabelAndInvoice">
                            {{ $t('fulfillment_eon_in_process.download_labels_and_invoices') }}
                            <v-icon class="material-icons-outlined px-1" small>downloading</v-icon>
                        </v-btn>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="right white--text tools-view-height mx-1" color="amarillo" @click="downloadOSAndPL" style="max-width: 40px !important; min-width: 40px !important" >
                                    <v-icon class="material-icons-outlined" small>content_paste_search</v-icon>
                                </v-btn>
                            </template>

                            <span>{{$t('fulfillments_prepaid_in_process.download_os_and_pl')}}</span>
                        </v-tooltip>
                    </v-row>
                </v-col>
            </v-row>
            <v-card class="px-0 size_buttons">
                <v-card-text class="py-3">
                    <v-row>
                        <v-col class="py-0">
                            <v-data-table class="px-0 my-2"
                                no-results-text="No hay resultados"
                                :headers="headers"
                                :items="props.items"
                                :search="props.search"
                                calculate-widths
                                :footer-props="{
                                    'items-per-page-text': this.$t('datatable.shown'),
                                    showFirstLastPage: true,
                                    firstIcon: 'mdi-arrow-collapse-left',
                                    lastIcon: 'mdi-arrow-collapse-right',
                                    pageText: '{0}-{1} ' + this.$t('datatable.of') + ' {2} ' + this.$t('datatable.results'),
                                    'show-current-page': true,
                                    'items-per-page-options': typeof props.rowsPP === 'undefined' ? [10, 25, 50, 100] : props.rowsPP}"
                                :page="1"
                                :mobile-breakpoint="0"
                                item-key="id"
                                show-select
                                v-model="selected"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                                        <template v-slot:activator="{ on }">
                                            <v-btn x-small v-if="!('link' in action)" icon @click="methodHandler(action.method, item.parameters)" :disabled="action.method === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                            <v-btn x-small v-else icon text :to="action.link" :disabled="action.link === null">
                                                <v-icon class="material-icons-outlined" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ action.name }}</span>
                                    </v-tooltip>
                                </template>
                                <!-- Id dot in cod orders in process. INDEX -->
                                <template v-slot:item.labelDownloaded="{ item }"> 
                                    <td v-if="item.labelDownload == 1" style="text-align:center !important">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                        {{ item.idIndex }}
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon class="material-icons-outlined" small color="#a5a5a5"> fiber_manual_record </v-icon> 
                                        {{ item.idIndex }}
                                    </td>
                                </template>

                                <!-- Id dot in cod orders in process. INDEX -->
                                <template v-slot:item.noteDownloaded="{ item }"> 
                                    <td v-if="item.noteDownload == 1" style="text-align:center">
                                        <v-icon small color="success2"> fiber_manual_record </v-icon> 
                                        {{ item.idIndex }}
                                    </td>
                                    <td v-else style="text-align:center">
                                        <v-icon class="material-icons-outlined" small color="#a5a5a5"> fiber_manual_record </v-icon> 
                                        {{ item.idIndex }}
                                    </td>
                                </template>
                                <v-data-footer :items-per-page-options="[]"></v-data-footer>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
            <v-dialog v-model="modalDelete" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h3 class="text-uppercase">
                                {{ $t('fulfillment_eon_in_process.modal.delete') }}
                            </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('fulfillment_eon_in_process.modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDelete = false">{{ $t('fulfillment_eon_in_process.no') }}</v-btn>
                            <v-btn color="error" @click="deleteOrder">
                                {{ $t('fulfillment_eon_in_process.yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalRestore" persistent max-width="90%">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h3 class="text-uppercase">
                                {{ $t('fulfillment_eon_in_process.restore_modal.restore') }}
                            </h3>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" justify="center" class="py-3 my-3 text-center">
                            <v-col cols="12">
                                <h5>{{ $t('fulfillment_eon_in_process.restore_modal.message') }}</h5>
                            </v-col>
                        </v-row>
                        
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalRestore = false">{{ $t('fulfillment_eon_in_process.no') }}</v-btn>
                            <v-btn color="error" @click="restoreOrder">
                                {{ $t('fulfillment_eon_in_process.restore_yes') }}
                            </v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalDetail" persistent max-width="90%" v-if="orderToShow != null">
                <v-card >
                    <v-card-title class="justify-center">
                        <v-row align="center" justify="center">
                            <h4 class="text-uppercase azul--text py-2">
                                {{ $t('fulfillment_eon_in_process.order_details.header') }} {{ this.orderToShow.unique_order_number }}
                            </h4>
                        </v-row>
                    </v-card-title>
                    
                    <div class="marker1-1" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_eon_in_process.details') }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_in_process.order_number") }}</label> <label>{{ this.orderToShow.unique_order_number }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_in_process.shipping_service") }}</label> <label>{{ this.orderToShow.shipping_service }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_in_process.date") }}</label> <label>{{ this.orderToShow.created_at }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_in_process.customer") }}</label> <label>{{ this.orderToShow.customer }}</label></div>
                    <div class="detail_content1"><label style="font-weight:bold">{{ $t("fulfillment_eon_in_process.buyer") }}</label> <label>{{ this.orderToShow.buyer }}</label></div>
                    
                    <div class="marker1-1 mt-2" style="font-weight:bold"><label class="mx-1">{{ $t('fulfillment_eon_in_process.products') }}</label></div>
                    <span>
                        <div v-for="(product, i) of orderToShow.products" :key="i" class="detail_content1"><label style="font-weight:bold">{{ product.sku }}</label> <label>{{ product.quantity }}</label></div>
                    </span>

                    <v-card-text class="mt-5">
                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn class="mx-3" text @click="modalDetail = false">{{ $t('fulfillment_eon_in_process.go_back') }}</v-btn>
                        </div>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>
            <v-dialog v-model="modalUpdate" persistent max-width="90%">
                <v-card style="min-width: 650px !important">
                    <v-card-text>
                        <v-row align="center" justify="center" class="pt-3 mt-3 text-center">
                            <v-col cols="12">
                                <h3>{{ $t('fulfillment_eon_pendings.update_order') }}</h3>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-text-field class="py-0" v-model="orderToShow.unique_order_number" :label="$t('fulfillment_eon_pendings.unique_order_number')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-text-field class="py-0" v-model="orderToShow.tracking_number" :label="$t('fulfillment_eon_pendings.tracking_number')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="py-0 my-0" justify="center" align="center">
                            <v-col cols="10" class="py-0 my-0">
                                <v-text-field class="py-0" v-model="orderToShow.consignee_name" :label="$t('fulfillment_eon_pendings.buyers_name')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-0 py-0 my-0" justify="center" align="center">
                            <v-col cols="11" class="py-0 my-0 mx-0 px-0" align="end" justify="end">
                                <v-btn class="mx-3" text @click="cancelUpdateOrder()">{{ $t('fulfillments_prepaid_returns.cancel') }}</v-btn>
                                <v-btn color="amarillo" class="white--text" @click="updateOrder()">
                                    {{ $t('fulfillment_eon_pendings.save') }}
                                </v-btn>    
                            </v-col>
                        </v-row>
                    </v-card-text> 
                    <div class="marker1"></div>
                </v-card>
            </v-dialog>

            <v-dialog v-model="modal_errors" persistent max-width="90%">
                <v-card>
                    <v-card-text>
                        <v-card flat class="mb-5">
                            <v-card-text class="py-0 my-0">
                                <v-card-title class="justify-center">
                                    <v-row align="center" justify="center">
                                        <h4 class="text-uppercase azul--text py-2">
                                            {{ $t('fulfillments_cod_pendings.summary_supply') }}
                                        </h4>
                                    </v-row>
                                </v-card-title>
                            </v-card-text>
                            <v-simple-table v-if="download_label_errors.length > 0" fixed-header height="300px">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th v-for="(th, key) in [$t('fulfillments_prepaid_pendings.order'), $t('fulfillments_prepaid_pendings.message')]" :key="key" class="text-left">{{ th }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, i) in download_label_errors" :key="i">
                                            <td>{{ item.order }}</td>
                                            <td>{{ item.message }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>

                        <div class="d-flex">
                            <v-spacer></v-spacer>
                            <v-btn color="amarillo" class="white--text" @click="modal_errors = false; index();">
                                {{ $t('fulfillments_prepaid_pendings.accept') }}
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>

        </v-container>        
    </div>
</template>
<script>
import JSZip from 'jszip';
import FileSaver from 'file-saver';
export default {
	computed: {
        headers(){
            return [
                { text: this.$t('fulfillment_eon_in_process.order'), value: 'uniqueOrderNumber' },
                { text: this.$t('fulfillment_eon_in_process.customer'), value: 'client.name', width:'30%'},
                { text: this.$t('fulfillment_eon_in_process.date'), value: 'date' },
                { text: this.$t('fulfillment_eon_in_process.shipping_service'), value: 'shippingService' },
                { text: this.$t('fulfillment_eon_in_process.actions'), value: 'actions' },
            ];
        },
        productsHeaders(){
            return [
                { text: this.$t('fulfillment_eon_pendings.sku'), value: 'sku', width: '60%' },
                { text: this.$t('fulfillment_eon_pendings.quantity'), value: 'quantity', width: '40%' },
                { text: '', value: 'actions', class: 'text-uppercase', width:'10%' }
            ];
        },  
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}, 
    },
    data(){
        return {
            props: {
                search: '',
                items: [],
            },
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            selected: [],
            modalDelete: false,
            modalRestore: false,
            modalDetail: false,
            modalUpdate: false,
            orderToUpdate: '',
            orderToDelete: '',
            orderToRestore: '',
            orderToShow: {},
            products: [],
            product: {
                sku: "",
                quantity: ""
            },
            bulk: {
                box: "",
                quantity: ""
            },
            boxes: [],
            actions:[
                {
                    name: this.$t('fulfillment_eon_in_process.detail'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                },
                {
                    name: this.$t('fulfillments_cod_sent.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'mode_edit'
                    },
                    method: 'askUpdate',
                },
                {
                    name: this.$t('fulfillment_eon_in_process.restore'),
                    icon: {
                        color: 'accent',
                        icon: 'history'
                    },
                    method: 'askRestore',
                },
                {
                    name: this.$t('fulfillment_eon_in_process.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                },
                {
                    name: this.$t('fulfillment_eon_in_process.download_guides'),
                    icon: {
                        color: 'accent',
                        icon: 'label'
                    },
                    method: 'downloadLabel',
                },
                {
                    name: this.$t('fulfillment_eon_in_process.download_invoice'),
                    icon: {
                        color: 'accent',
                        icon: 'description'
                    },
                    method: 'downloadInvoice',
                }
            ],
            actions2: [
            {
                    name: this.$t('fulfillment_eon_in_process.detail'),
                    icon: {
                        color: 'accent',
                        icon: 'info'
                    },
                    method: 'showDetails',
                },
                {
                    name: this.$t('fulfillments_cod_sent.edit'),
                    icon: {
                        color: 'accent',
                        icon: 'mode_edit'
                    },
                    method: 'askUpdate',
                },
                {
                    name: this.$t('fulfillment_eon_in_process.restore'),
                    icon: {
                        color: 'accent',
                        icon: 'history'
                    },
                    method: 'askRestore',
                },
                {
                    name: this.$t('fulfillment_eon_in_process.delete'),
                    icon: {
                        color: 'accent',
                        icon: 'delete'
                    },
                    method: 'askDelete',
                },
                {
                    name: this.$t('fulfillment_eon_in_process.download_guides'),
                    icon: {
                        color: 'accent',
                        icon: 'label'
                    },
                    method: 'downloadLabel',
                },
                {
                    name: this.$t('fulfillment_eon_in_process.download_invoice'),
                    icon: {
                        color: 'accent',
                        icon: 'description'
                    },
                    method: 'downloadInvoice',
                },
                {
                    name: this.$t('fulfillment_eon_send.send_feedback'),
                    icon: {
                        color: 'accent',
                        icon: 'local_shipping'
                    },
                    method: 'sendFeedback',
                },
            ],
            download_label_errors: [],
            modal_errors: false
        }
    },
    mounted(){
        this.$store.state.module = "headers.fulfillment_eon_in_process";
        this.index();
    },
    methods: {
        methodHandler(object, params){
            this[object](params)
        },
        index(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.props.items = [];
            this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_orders?status=READY', {headers: {Authorization: lsToken}})
            .then((response) => {
                // Get Shippment_services
                this.$store.state.loaderProps.visible = true;
                response.data.data.forEach( x => { 
                    x = x.attributes;
                    x.date = x.createdAt.substring(0,10);

                    if(x.automaticFeedback == 0 || x.automaticFeedback == false) x['actions'] = JSON.parse(JSON.stringify(this.actions2));
                    else x['actions'] = JSON.parse(JSON.stringify(this.actions));

                    x.parameters = x;
                    this.props.items.push(x);
                })
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        showDetails(order){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_info/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.orderToShow = {
                    unique_order_number: response.data.order.unique_order_number,
                    created_at: response.data.order.created_at.substring(0,10),
                    shipping_service: response.data.order.shipping_service,
                    customer: response.data.order.name,
                    buyer: response.data.order.consignee_name,
                    products: JSON.parse(response.data.order.products).products
                }
                this.$store.state.loaderProps.visible = false;
                this.modalDetail = true;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        askDelete(order){
            this.modalDelete = true;
            this.orderToDelete = order;
        },
        askRestore(order){
            this.modalRestore = true;
            this.orderToRestore = order;
        },
        restoreOrder(){
            this.$store.state.loaderProps.visible = true;
            if(this.orderToRestore != ''){
                const lsToken = localStorage.getItem("access_token");
                this.$http.put(this.$store.state.chronosApi + 'external_shipment/restore/' + this.orderToRestore.id, {headers: {Authorization: lsToken}})
                .then((response) => {
                    if(response.status === 200) {
                        this.orderToRestore = '';
                        this.modalRestore = false;
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('fulfillments_cod_sent.restored')
                        }
                        this.index();
                    }
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.$store.state.loaderProps.visible = false;
                })
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'error',
                    text: "error"
                }
            }
        },
        deleteOrder(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.delete(this.$store.state.chronosApi + 'external_shipment/delete/' + this.orderToDelete.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                response;
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('fulfillment_eon_pendings.deleted')
                }
                this.modalDelete = false;
                this.index();
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        downloadLabel: async function (order){
            this.$store.state.loaderProps.visible = true;
            // var zip = new JSZip();
            const lsToken = localStorage.getItem("access_token");
            const response = await this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_labels_urls/' + order.id, {headers: {Authorization: lsToken}})
            var urls = ""
            
            if(typeof response.data.data == "string") urls = JSON.parse(response.data.data);
            else urls = response.data.data

            for(var i = 0; i < urls.length; i++){
                const file = await this.getLabelFile(order.id, urls[i].index ?? 0, lsToken)

                if(file.data.code == 200) 
                    FileSaver.saveAs(file.data.success.arguments.file, file.data.success.arguments.filename);
                else {
                    this.snackbar = {
                        visible: true,
                        color: 'error',
                        text: this.$t('fulfillment_eon_in_process.download_label_error') + (i + 1)
                    };
                }
            }

            this.$store.state.loaderProps.visible = false;
        },
        getLabelFile: async function (id, index, lsToken){
            const file = await this.$http.get(this.$store.state.chronosApi + 'external_shipment/download_label/' + id + '/' + index, {headers: {Authorization: lsToken}})
            return file
        },
        downloadInvoice: async function (order){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const response = await this.$http.get(this.$store.state.chronosApi + 'external_shipment/download_files/bulk?id=' + order.id, {headers: {Authorization: lsToken}})
            // this.$http.get(this.$store.state.chronosApi + 'external_shipment/download_files/bulk?id=' + order.id, {headers: {Authorization: lsToken}, responseType:'blob' })
            
            if(response.data.data){
                response.data.data.external_shipments.forEach(es => {      
                    if(es.attached_files == null) {
                        this.snackbar = {
                            visible: true,
                            color: 'warning',
                            text: "file not found"
                        }

                        this.$store.state.loaderProps.visible = false;
                        return;
                    }
                    if(es.attached_files.length > 0){
                        var zip = new JSZip();
                        var count = 1;
                        let promises = [];
                        let responses = [];
                        es.attached_files.forEach(file => {
                            let file_promise = this.$http.get(file, {headers: {Authorization: lsToken}, responseType:'blob' })
                            .then(response1 => {
                                responses.push(response1);
                            });
                            promises.push(file_promise);
                        });

                        Promise.all(promises).then( () => {
                        if(responses.length == es.attached_files.length){
                            responses.forEach(response => {

                                let type = response.headers['content-type'].split("/")[1]
                                let blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
                                
                                zip.file(es.order + count.toString() + "." + type, blob);
                                count++;
                            });    

                            this.$store.state.loaderProps.visible = false;
                            zip.generateAsync({type:"blob"}).then(function(content) {
                                FileSaver.saveAs(content, "Invoice.zip")
                            }, function(err){
                                console.log(err)
                            })
                        }
                    })  
                    }
                })
            }
        },
        downloadLabelByOrder: async function(order, lsToken){
            const {data} = await this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_labels_urls/' + order.id, {headers: {Authorization: lsToken}})
            return data;
        },
        getInvoiceFile: async function (order, file, lsToken, zip, count){
            const res = await this.$http.get(file, {headers: {Authorization: lsToken}, responseType:'blob' })
            .then(response => {
                let type = response.headers['content-type'].split("/")[1]
                let blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
                zip.file(order.uniqueOrderNumber + count.toString() + "-B." + type, blob); 
            });
            res;
            return "OK";
        },
        downloadInvoiceByOrder: async function (order, lsToken){
            const response = await this.$http.get(this.$store.state.chronosApi + 'external_shipment/download_files/bulk?id=' + order.id, {headers: {Authorization: lsToken}})            
            if(response.data.data){
                return response.data.data.external_shipments;
            }
        },
        downloadLabelAndInvoice: async function(){
            this.$store.state.loaderProps.visible = true;
            this.download_label_errors = [];
            const lsToken = localStorage.getItem("access_token");
            var zip = new JSZip();
            var shipments_ids = "";
            this.selected.forEach(image => {
                shipments_ids += image.id + ","; 
            })
            shipments_ids = shipments_ids.substring(0, shipments_ids.length - 1);

            for(var i = 0; i < this.selected.length; i++){
                this.$store.state.loaderProps.text = localStorage.getItem('language') == 'en' ? "LOADING... " + (i + 1) + " of " + this.selected.length.toString() : "CARGANDO... " + (i + 1) +  " de " + this.selected.length.toString()

                const labelsUrls = await this.downloadLabelByOrder(this.selected[i], lsToken);
                labelsUrls.data = JSON.parse(labelsUrls.data);
                                
                for(var k = 0; k < labelsUrls.data.length; k++){
                    const file = await this.getLabelFile(this.selected[i].id, labelsUrls.data[k].index ?? 0, lsToken);

                    if(file.data.code == 200) {
                        const base64Data = file.data.success.arguments.file.split(",")[1] || file.data.success.arguments.file;
                        zip.file("A-" + file.data.success.arguments.filename, base64Data, { base64: true });
                    }
                    else {
                        this.download_label_errors.push({
                            order: this.selected[i].uniqueOrderNumber,
                            message: this.$t('fulfillment_eon_in_process.download_label_error') + (k + 1)
                        })
                    }
                }
                
                const urls = await this.downloadInvoiceByOrder(this.selected[i], lsToken);
                for(var j = 0; j < urls.length; j++){
                    if(urls[j].attached_files != null){
                        for(var h = 0; h < urls[j].attached_files.length; h++){
                            const result = await this.getInvoiceFile(this.selected[i],urls[j].attached_files[h], lsToken, zip, h);
                            result;
                        }
                    }
                }
            }

            if(this.download_label_errors.length > 0) this.modal_errors = true;

            const picking_list = await this.downloadPickingList(shipments_ids, lsToken);
            zip.file("Picking_List.pdf", picking_list);
            const order_summary = await this.downloadOrderSummary(shipments_ids, lsToken);
            zip.file("Order_Summary.pdf", order_summary);

            await zip.generateAsync({type:"blob"}).then(function(content) {
                FileSaver.saveAs(content, "LabelsAndInvoices.zip")
            }, function(err){
                console.log(err)
            })
            this.$store.state.loaderProps.visible = false;
            this.$store.state.loaderProps.text = localStorage.getItem('language') == 'en' ? "LOADING... " : "CARGANDO... "
        },
        downloadPickingList: async function(ids, lsToken){
            const {data} = await this.$http.get(this.$store.state.chronosApi + 'external_shipment/picking_list/'+ ids, {headers: {Authorization: lsToken}, responseType:'blob' })
            return data;
        },
        downloadOrderSummary: async function(ids, lsToken){
            const {data} = await this.$http.get(this.$store.state.chronosApi + 'external_shipment/order_summary/'+ ids, {headers: {Authorization: lsToken}, responseType:'blob' })
            return data;
        },
        askUpdate(order){
            this.orderToUpdate = order;

            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'external_shipment/get_info/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.orderToShow = {
                    id: order.id,
                    unique_order_number: response.data.order.unique_order_number,
                    created_at: response.data.order.created_at.substring(0,10),
                
                    shipping_service: response.data.order.shipping_service,
                    customer: response.data.order.name,
                    consignee_name: response.data.order.consignee_name,
                    tracking_number: response.data.order.tracking_number,
                    client_id: response.data.order.client_id,
                    warehouse_id: this.$store.state.warehouseId
                }
                this.$store.state.clientId = response.data.order.client_id;
                
                this.$store.state.loaderProps.visible = false;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
                this.modalUpdate = true;
            })
        },
        cancelUpdateOrder(){
            this.orderToShow = "";
            this.modalUpdate = false;
        },
        downloadOSAndPL: async function()
        {
            if(this.selected.length == 0) return;
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;
            var zip = new JSZip();
            var shipments_ids = "";
            this.selected.forEach(image => {
                shipments_ids += image.id + ","; 
            })
            shipments_ids = shipments_ids.substring(0, shipments_ids.length - 1);
            
            const pickeoList = await this.downloadPickingList(shipments_ids, lsToken);
            zip.file("Lista_de_pickeo.pdf", pickeoList);

            const ordersSummary = await this.downloadOrderSummary(shipments_ids, lsToken);
            zip.file("Resumen_de_ordenes.pdf", ordersSummary);

            this.$store.state.loaderProps.visible = false;
            zip.generateAsync({type:"blob"}).then(function(content) {
                FileSaver.saveAs(content, "Picking_List-Order_Summary.zip")
            }, function(err){
                console.log(err)
            })
        },
        sendFeedback(order){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.get(this.$store.state.chronosApi + 'external_shipment/update_order_feedback/' + order.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                // Get Shippment_services
                this.$store.state.loaderProps.visible = false;
                response;
                this.snackbar = {
                    visible: true,
                    color: 'success2',
                    text: this.$t('fulfillment_eon_send.feedback_sent')
                }

                this.index();
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
            })
        },
        exportShipments: async function(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            const data = await this.$http.get(this.$store.state.chronosApi + 'external_shipment/in-process/export', {headers: {Authorization: lsToken}})

            var rows = data.data.split('\n');
            var content = [];
            rows.forEach(r => {
                content.push(r.split(','));
            })

            let csvContent = "data:text/csv;charset=utf-8," + content.map(e => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            var downloadLink = document.createElement("a");
            downloadLink.href = encodedUri;

            downloadLink.download = "Procesados - "+ new Date().toISOString().slice(0, 10) + ".csv";
            downloadLink.click();
            this.$store.state.loaderProps.visible = false;
        },
        updateOrder(){
            this.$store.state.loaderProps.visible = true;
            const lsToken = localStorage.getItem("access_token");
            this.$http.post(this.$store.state.chronosApi + 'external_shipment/update/' + this.orderToShow.id, this.orderToShow, {headers: {Authorization: lsToken}})
            .then((response) => {
                response;
                this.index();    
                this.$store.state.loaderProps.visible = false;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.$store.state.loaderProps.visible = false;
                this.modalUpdate = false;
            })
        }
    },
    watch:{
        '$route.params.shipping_service': function() {
            location.reload();
        }
    }
}
</script>

<style lang="scss">
    
</style>