<template>
    <div class="px-0">
        <v-container class="px-0 py-5" style="max-width: 100% !important">
            <v-card class="px-0 py-3 size">
                <v-row class="px-5 pt-5">
                    <v-col cols="12" align="center" justify="center">    
                        <h3 class="azul--text text-uppercase">{{ $t('edit_customer.update_fees') }} {{ client_name }}</h3>
                    </v-col>
                </v-row>
                <v-card-text class="px-0">
                    <v-form ref="form" lazy-validation @submit.prevent="save">
                        <!-- Settings -->
                        <v-row class="px-5 py-0 mt-2 mb-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-text-field class="py-0" v-model="storage_per_pallet_cost" :label="$t('edit_customer.storage_per_pallet')" :rules="rules_required"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="px-5 py-0 mt-2 mb-0" justify="center" align="center">
                            <v-col cols="6" class="py-0">
                                <v-expansion-panels>
                                    <v-expansion-panel v-for="(item, i) in settings" :key="i">
                                        <v-expansion-panel-header>
                                            {{$t('create_customer.' + i)}}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                                <v-col cols="6" class="py-0" style="display:flex">
                                                    <v-text-field class="py-0" v-model="settings[i].fulfillment" :label="$t('create_customer.fulfillment_quote')"></v-text-field>
                                                </v-col>
                                                <v-col cols="6" class="py-0" style="display:flex">
                                                    <v-text-field class="py-0" v-model="settings[i].label" :label="$t('create_customer.label_printing_quote')"></v-text-field>
                                                </v-col>
                                            </v-row>

                                             <!-- FEDEX -->
                                            <v-row class="px-0 py-0 my-0" justify="center" align="center" v-if="item.shipping_service == 'FEDEX'">
                                                <v-col cols="6" class="py-0" style="display:flex">
                                                    <v-text-field class="py-0" v-model="settings[i].zone_1" :label="$t('create_customer.zone_1')"></v-text-field>
                                                </v-col>
                                                <v-col cols="6" class="py-0" style="display:flex">
                                                    <v-text-field class="py-0" v-model="settings[i].zone_2" :label="$t('create_customer.zone_2')"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="px-0 py-0 my-0" justify="center" align="center" v-if="item.shipping_service == 'FEDEX'">
                                                <v-col cols="6" class="py-0" style="display:flex">
                                                    <v-text-field class="py-0" v-model="settings[i].zone_3" :label="$t('create_customer.zone_3')"></v-text-field>
                                                </v-col>
                                                <v-col cols="6" class="py-0" style="display:flex">
                                                    <v-text-field class="py-0" v-model="settings[i].zone_4" :label="$t('create_customer.zone_4')"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="px-0 py-0 my-0" justify="center" align="center" v-if="item.shipping_service == 'FEDEX'">
                                                <v-col cols="6" class="py-0" style="display:flex">
                                                    <v-text-field class="py-0" v-model="settings[i].zone_5" :label="$t('create_customer.zone_5')"></v-text-field>
                                                </v-col>
                                                <v-col cols="6" class="py-0" style="display:flex">
                                                    <v-text-field class="py-0" v-model="settings[i].zone_6" :label="$t('create_customer.zone_6')"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="px-0 py-0 my-0" justify="center" align="center" v-if="item.shipping_service == 'FEDEX'">
                                                <v-col cols="6" class="py-0" style="display:flex">
                                                    <v-text-field class="py-0" v-model="settings[i].zone_7" :label="$t('create_customer.zone_7')"></v-text-field>
                                                </v-col>
                                                <v-col cols="6" class="py-0" style="display:flex">
                                                    <v-text-field class="py-0" v-model="settings[i].zone_8" :label="$t('create_customer.zone_8')"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                                <v-col cols="6" class="py-0" style="display:flex">
                                                    <v-autocomplete class="mt-0 py-0" v-model="settings[i].is_porcentual" item-text="text" item-value="value" :items="behaviors" :label="$t('create_customer.is_porcentual')" ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="6" class="py-0" style="display:flex">
                                                    <v-text-field class="py-0" v-model="settings[i].cod_fee" :label="$t('create_customer.cod_collection_quote')"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                                <v-col cols="6" class="py-0" style="display:flex">
                                                    <v-text-field class="py-0" v-model="settings[i].return_1kg" :label="$t('create_customer.return1kg_quote')"></v-text-field>
                                                </v-col>
                                                <v-col cols="6" class="py-0" style="display:flex">
                                                    <v-text-field class="py-0" v-model="settings[i].extended_zone" :label="$t('create_customer.ext_zone_quote')"></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row class="px-0 py-0 my-0" justify="center" align="center">
                                                <v-col cols="6" class="py-0" style="display:flex">
                                                    <v-text-field class="py-0" v-model="settings[i].packing_1kg" :label="$t('create_customer.packing1kg_quote')"></v-text-field>
                                                </v-col>

                                                <v-col cols="6" class="py-0" style="display:flex" v-if="item.shipping_service != 'FEDEX'" >
                                                    <v-text-field class="py-0" v-model="settings[i].shipping_1kg" :label="$t('create_customer.shiping1kg_quote')"></v-text-field>
                                                </v-col>
                                                <v-spacer v-if="item.shipping_service == 'FEDEX'"></v-spacer>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-form>
                    
                    <v-row class="px-5 py-0 mt-2" justify="center" align="center">
                        <v-col cols="6" justify="end" align="end">
                            <v-btn class="right px-3 mx-4" color="white" @click="cancel">
                                {{ $t('edit_customer.cancel') }}
                            </v-btn>
                            <v-btn class="right white--text" color="amarillo" @click="save" v-permission="'client.update'">
                                {{ $t('edit_customer.save') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>    
        </v-container>        
        <v-snackbar v-model="snackbar.visible" :timeout="4000" :color="snackbar.color" style="text-align: center;">{{ snackbar.text }}</v-snackbar>
    </div>
</template>
<script>
export default {
    computed: {
        rules_required(){return [v => !!v || this.$t('create_appointment.validations.required_field')]}
    },
    data(){
        return {
            id: this.$route.params.id,
            snackbar: {
                visible: false,
                color: 'primary',
                text: ''
            },
            client_name: "",
            parcel_names: [
                {text: "FEDEX", value: "fedex"}, 
                {text: "QUIKEN", value: "quiken"}, 
                {text: "YEGO", value: "yego"}, 
                {text: "OTRA", value: "otra"}, 
                {text: "MERQ", value: "merq"}, 
                {text: "JTEXPRESS", value: "jtexpress"},
                {text: "AFIMEX", value: "afimex"},
                {text: "CEXPRESS", value: "cexpress"}
            ],
            storage_per_pallet_cost: 0,
            settings: {
                fedex: {
                    shipping_service: 'FEDEX',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    zone_1: 0,
                    zone_2: 0,
                    zone_3: 0,
                    zone_4: 0,
                    zone_5: 0,
                    zone_6: 0,
                    zone_7: 0,
                    zone_8: 0,
                    return_1kg: 0,
                    packing_1kg: 0,
                    extended_zone: 0,
                    is_porcentual: false,
                },
                quiken: {
                    shipping_service: 'QUIKEN',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    return_1kg: 0,
                    packing_1kg: 0,
                    extended_zone: 0,
                    is_porcentual: false,
                },
                yego: {
                    shipping_service: 'YEGO',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    return_1kg: 0,
                    packing_1kg: 0,
                    extended_zone: 0,
                    is_porcentual: false,
                },
                otra: {
                    shipping_service: 'OTRA',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    return_1kg: 0,
                    packing_1kg: 0,
                    extended_zone: 0,
                    is_porcentual: false,
                },
                merq: {
                    shipping_service: 'MERQ',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    return_1kg: 0,
                    packing_1kg: 0,
                    extended_zone: 0,
                    is_porcentual: false,
                },
                jtexpress: {
                    shipping_service: 'JTEXPRESS',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    return_1kg: 0,
                    packing_1kg: 0,
                    extended_zone: 0,
                    is_porcentual: false,
                },
                afimex: {
                    shipping_service: 'AFIMEX',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    return_1kg: 0,
                    packing_1kg: 0,
                    extended_zone: 0,
                    is_porcentual: false,
                },
                cexpress: {
                    shipping_service: 'CEXPRESS',
                    fulfillment: 0,
                    label: 0,
                    cod_fee: 0,
                    shipping_1kg: 0,
                    return_1kg: 0,
                    packing_1kg: 0,
                    extended_zone: 0,
                    is_porcentual: false,
                }
            },
            behaviors: [{ text: this.$t("create_customer.porcentual"), value: true }, { text: this.$t("create_customer.fixed"), value: false }]
        }
    },
    mounted(){
        this.index();
        this.$store.state.module = "headers.edit_customer";
    },
    methods: {
        index(){
            // Get countries
            const lsToken = localStorage.getItem("access_token");
            this.$store.state.loaderProps.visible = true;

            // Get current customer
            this.$http.get(this.$store.state.chronosApi + 'clients/' + this.id, {headers: {Authorization: lsToken}})
            .then((response) => {
                this.client_name = response.data.data.attributes.name;
                this.storage_per_pallet_cost = response.data.data.attributes.storage_per_pallet_cost;
                response.data.data.attributes.settings.forEach( x => {
                    x.forEach(y => {
                        var index = this.parcel_names.findIndex(x => {
                            if(x.text == y.shipping_service) return x;
                        })

                        if(y.text == 'fulfillment') {this.settings[this.parcel_names[index].value].fulfillment = y.value; return;}
                        else if(y.text == 'label') {this.settings[this.parcel_names[index].value].label = y.value; return;}
                        else if(y.text == 'cod_fee') {
                            this.settings[this.parcel_names[index].value].cod_fee = y.value;
                            this.settings[this.parcel_names[index].value].is_porcentual = y.is_porcentual; return;
                        }
                        else if(y.text == 'shipping_1kg') {this.settings[this.parcel_names[index].value].shipping_1kg = y.value; return;}
                        else if(y.text == 'zone_1') {this.settings[this.parcel_names[index].value].zone_1 = y.value; return;}
                        else if(y.text == 'zone_2') {this.settings[this.parcel_names[index].value].zone_2 = y.value; return;}
                        else if(y.text == 'zone_3') {this.settings[this.parcel_names[index].value].zone_3 = y.value; return;}
                        else if(y.text == 'zone_4') {this.settings[this.parcel_names[index].value].zone_4 = y.value; return;}
                        else if(y.text == 'zone_5') {this.settings[this.parcel_names[index].value].zone_5 = y.value; return;}
                        else if(y.text == 'zone_6') {this.settings[this.parcel_names[index].value].zone_6 = y.value; return;}
                        else if(y.text == 'zone_7') {this.settings[this.parcel_names[index].value].zone_7 = y.value; return;}
                        else if(y.text == 'zone_8') {this.settings[this.parcel_names[index].value].zone_8 = y.value; return;}
                        else if(y.text == 'return_1kg') {this.settings[this.parcel_names[index].value].return_1kg = y.value; return;}
                        else if(y.text == 'packing_1kg') {this.settings[this.parcel_names[index].value].packing_1kg = y.value; return;}
                        else if(y.text == 'extended_zone') {this.settings[this.parcel_names[index].value].extended_zone = y.value; return;}
                        else if(y.text == 'extended_zone') {this.settings[this.parcel_names[index].value].extended_zone = y.value; return;}
                    })
                })
                this.$store.state.loaderProps.visible = false;
            })
            .catch((response) => {
                console.log(response)
                this.$store.state.loaderProps.visible = false;
                if(response.status !== 200){
                    this.snackbar = {
                        visible: true,
                        color: 'red',
                        text: this.$t('pendings.responses.error')
                    }
                }
            });
        },
        save(){
            const validate = this.$refs.form.validate()

            
            if(validate){
                this.$store.state.loaderProps.visible = true;

                const lsToken = localStorage.getItem("access_token");
                this.$http.put(this.$store.state.chronosApi + 'clientsFees/' + this.id, { "settings": this.settings, "storage_per_pallet_cost": this.storage_per_pallet_cost}, { headers: { Authorization: lsToken } })
                .then((response) => {
                    if(response.status === 200){
                        this.snackbar = {
                            visible: true,
                            color: 'success2',
                            text: this.$t('create_custom_agent.responses.success')
                        }
                        this.$router.push({ name: "CustomersIndex" });  
                    }
                    this.$store.state.loaderProps.visible = false;
                })
                .catch((response) => {
                    this.$store.state.loaderProps.visible = false;
                    if(response.status !== 200){
                        this.snackbar = {
                            visible: true,
                            color: 'red',
                            text: this.$t('pendings.responses.error')
                        }
                    }
                });
            }
            else{
                this.snackbar = {
                    visible: true,
                    color: 'red',
                    text: this.$t('create_customer.invalid_form')
                }
            }
        },
        cancel(){
            this.$router.go(-1);
        },
    },
    watch:{
        
    }
}
</script>

<style lang="css">
</style>