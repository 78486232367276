<template>
    <v-navigation-drawer :mini-variant.sync="$store.state.minimenu" width="180" :expand-on-hover="false"
        v-model="$store.state.menu" app src="/img/Detalle.png" class="sidemenu-border">
        <v-list-item class="py-0 px-2 item">
            <v-list-item-content class="justify-center">
                <div v-if="!$store.state.minimenu" class="d-flex">
                    <v-row align="center" class="px-0 mx-0" justify="center">
                        <v-col cols="10" class="my-6 px-0 mx-0">
                            <v-img height="130" width="160" src="/img/sidebar.png"></v-img>
                        </v-col>
                    </v-row>
                </div>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense tile flat dark class="sidebar-menu">
            <template v-for="item in this.menu">
                <v-list-item v-if="item.submenu.length <= 0" :key="item.title" :to="item.link"
                    :inactive="item.link === null" v-permission:any="item.permission"
                    active-class="white--text border-amarillo">
                    <v-list-item-title>{{ $t('sidebar_menu.' + item.title) }}</v-list-item-title>
                </v-list-item>

                <!-- else if it has children -->
                <v-list-group v-else :group="item.link" :key="item.id" v-permission:any="item.permission"
                    active-class="amarillo--text">
                    <!-- this template is for the title of top-level items with children -->
                    <template #activator>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ $t('sidebar_menu.' + item.title) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <!-- this template is for the children/sub-items (2nd level) -->
                    <template v-for="subItem in item.submenu" sub-group>
                        <!-- another v-if to determine if there's a 3rd level -->
                        <!-- if there is NOT a 3rd level -->
                        <v-list-item v-if="!subItem.submenu" class="ml-5" :key="subItem.title"
                            :to="item.link + subItem.link" v-permission:any="subItem.permission">
                            <v-list-item-title class="ml-0">
                                {{ $t('sidebar_menu.' + subItem.title) }}
                            </v-list-item-title>
                        </v-list-item>
                        <!-- if there is a 3rd level -->
                        <v-list-group v-else :group="subItem.link" sub-group :key="subItem.id"
                            active-class="amarillo--text" v-permission:any="subItem.permission">
                            <template #activator>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ $t('sidebar_menu.' + subItem.title) }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <template v-for="(subSubItem, k) in subItem.submenu">
                                <v-list-item :key="`subheader-${k}`" :value="true"
                                    :to="item.link + subItem.link + subSubItem.link"
                                    v-permission:any="subSubItem.permission">
                                    <v-list-item-title>{{ $t('sidebar_menu.' + subSubItem.title) }}</v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list-group>
                    </template>
                </v-list-group>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'MenuLateral',
    data() {
        return {
            menu: [
                {
                    id: 1,
                    title: 'dashboard',
                    submenu: [],
                    link: '/dashboard',
                    permission: 'dashboard.show'
                },
                {
                    id: 2,
                    title: 'backlog',
                    submenu: [],
                    link: '/backlog',
                    permission: 'service.index'
                },
                {
                    id: 3,
                    title: 'customers',
                    link: '/customers',
                    submenu: [],
                    permission: "client.index"
                },
                {
                    id: 4,
                    title: 'search',
                    link: '/basic_search',
                    submenu: [],
                    permission: "basic_search.index"
                },
                {
                    id: 5,
                    title: 'inventory',
                    link: '',
                    submenu: [
                        { id: 1, title: 'categories', link: '/categories', permission: "category.index" },
                        { id: 2, title: 'products', link: '/products', permission: "product.index" },
                        { id: 3, title: 'kits', link: '/kits', permission: "kit.index" },
                    ],
                    permission: "category.index|kit.index|product.index"
                },
                {
                    id: 6,
                    title: 'income',
                    link: '',
                    submenu: [
                        { id: 1, title: 'create_new', link: '/create-appointment/national/income', permission: "appointment.create|n_pending.create" },
                        { id: 2, title: 'pendings', link: '/national-pendings/income', permission: "n_pending.index" },
                        { id: 3, title: 'operation', link: '/operation/pendings/national/income', permission: "op_pendings.index|op_pendings.show|op_on_premises.index|op_on_premises.show|op_in_process.index|op_in_process.show|op_pending_out.index|op_pending_out.show" },
                        { id: 4, title: 'processed', link: '/national-processed/income', permission: "n_processed.index" },
                    ],
                    permission: "n_pending.index|n_processed.index|operation.index|operation.show|op_pendings.index|op_pendings.show|op_on_premises.index|op_on_premises.show|op_in_process.index|op_in_process.show|op_pending_out.index|op_pending_out.show"
                },
                {
                    id: 7,
                    title: 'outcome',
                    link: '',
                    submenu: [
                        { id: 1, title: 'create_new', link: '/create-appointment/national/outcome', permission: "appointment.create|n_pending.create" },
                        { id: 2, title: 'pendings', link: '/national-pendings/outcome', permission: "n_pending.index" },
                        { id: 3, title: 'operation', link: '/operation/pendings/national/outcome', permission: "op_pendings.index|op_pendings.show|op_on_premises.index|op_on_premises.show|op_in_process.index|op_in_process.show|op_pending_out.index|op_pending_out.show" },
                        { id: 4, title: 'processed', link: '/national-processed/outcome', permission: "n_processed.index" },
                    ],
                    permission: "n_pending.index|n_processed.index|operation.index|operation.show|op_pendings.index|op_pendings.show|op_on_premises.index|op_on_premises.show|op_in_process.index|op_in_process.show|op_pending_out.index|op_pending_out.show"
                },
                {
                    id: 8,
                    title: 'import',
                    link: '',
                    submenu: [
                        { id: 1, title: 'create_new', link: '/create-appointment/international/income', permission: "appointment.create|i_pending.create" },
                        { id: 2, title: 'pendings', link: '/international-pendings/income', permission: "i_pending.index" },
                        // { id: 3, title: 'no_annex', link: '/international-no-annex', permission: "i_without_annex.index"},
                        { id: 5, title: 'operation', link: '/operation/pendings/international/income', permission: "op_pendings.index|op_pendings.show|op_on_premises.index|op_on_premises.show|op_in_process.index|op_in_process.show|op_pending_out.index|op_pending_out.show" },
                        // { id: 6, title: 'digital_heritage', link: '/international-digital-heritage', permission: "i_digital_heritage.index"},
                        { id: 7, title: 'processed', link: '/international-processed/income', permission: "i_processed.index" }
                    ],
                    permission: "i_pending.index|i_processed.index|operation.index|operation.show|op_pendings.index|op_pendings.show|op_on_premises.index|op_on_premises.show|op_in_process.index|op_in_process.show|op_pending_out.index|op_pending_out.show"
                },{
                    id: 9,
                    title: 'export',
                    link: '',
                    submenu: [
                        { id: 1, title: 'create_new', link: '/create-appointment/international/outcome', permission: "appointment.create|i_pending.create" },
                        { id: 2, title: 'pendings', link: '/international-pendings/outcome', permission: "i_pending.index" },
                        // { id: 3, title: 'no_annex', link: '/international-no-annex', permission: "i_without_annex.index"},
                        { id: 5, title: 'operation', link: '/operation/pendings/international/outcome', permission: "op_pendings.index|op_pendings.show|op_on_premises.index|op_on_premises.show|op_in_process.index|op_in_process.show|op_pending_out.index|op_pending_out.show" },
                        // { id: 6, title: 'digital_heritage', link: '/international-digital-heritage', permission: "i_digital_heritage.index"},
                        { id: 7, title: 'processed', link: '/international-processed/outcome', permission: "i_processed.index" }
                    ],
                    permission: "i_pending.index|i_processed.index|operation.index|operation.show|op_pendings.index|op_pendings.show|op_on_premises.index|op_on_premises.show|op_in_process.index|op_in_process.show|op_pending_out.index|op_pending_out.show"
                },
                {
                    id: 10,
                    title: 'compliance',
                    link: '',
                    submenu: [
                        { id: 1, title: 'no_annex', link: '/compliance-no-annex', permission: "c_without_annex.index" },
                        { id: 2, title: 'processed', link: '/compliance-processed', permission: "c_processed.index" },
                        { id: 3, title: 'digital_heritage', link: '/compliance-digital-heritage', permission: "c_digital_heritage.index" },
                    ],
                    permission: "c_without_annex.index|c_processed.index|c_digital_heritage.index"
                },
                {
                    id: 11,
                    title: 'relocations',
                    link: '/relocations',
                    submenu: [],
                    permission: "relocation.index|relocation.show"
                },
                // {
                //     id: 10,
                //     title: 'operation',
                //     link: '/operation/pendings',
                //     submenu: [],
                //     permission: "operation.index|operation.show"
                // },
                // customer views
                {
                    id: 12,
                    title: 'dashboard_c',
                    link: '/home',
                    submenu: [],
                    permission: "customer.show|customer_fulfillment.show|advanced_customer_fulfillment.show|customer_fulfillment_eon.show|customer_all_access.show"
                },
                // customers inventory
                {
                    id: 13,
                    title: 'my_inventory',
                    link: '/my_inventory',
                    submenu: [],
                    permission: "customer.show|customer_fulfillment.show|advanced_customer_fulfillment.show|customer_fulfillment_eon.show|customer_all_access.show"
                },
                // customers appointments
                {
                    id: 14,
                    title: 'my_operations',
                    link: '/customer_appointments',
                    submenu: [],
                    permission: "customer.show|customer_fulfillment.show|advanced_customer_fulfillment.show|customer_fulfillment_eon.show|customer_all_access.show"
                }
            ],
            shipping_services: [
                "fedex",
                "min",
                "pxpress",
                "dhl",
                "estafeta",
                "ups",
                "usps",
                "quiken",
                "yego",
                "hermes",
                "otra",
                "merq",
                "jtexpress",
                "afimex",
                "eon",
                "cexpress"
            ]
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        index() {
            var counter = 15;
            // Advanced Fulfillment
            var sub = [];
            this.shipping_services.forEach(x => {
                sub.push({
                    id: counter++,
                    title: x,
                    submenu: [
                        { id: 1, title: 'pendings', link: '/advanced-fulfillment/pendings/' + x, permission: x + "_advanced_fulfillment.process" },
                        { id: 2, title: 'to_be_supplied', link: '/advanced-fulfillment/to_be_supplied/' + x, permission: x + "_advanced_fulfillment.process" },
                        { id: 3, title: 'supplied', link: '/advanced-fulfillment/supplied/' + x, permission: x + "_advanced_fulfillment.process" },
                        { id: 4, title: 'sent', link: '/advanced-fulfillment/sent/' + x, permission: x + "_advanced_fulfillment.process" },
                        { id: 5, title: 'returned', link: '/advanced-fulfillment/returned/' + x, permission: x + "_advanced_fulfillment.process" }
                    ],
                    link: '',
                    permission: x + "_advanced_fulfillment.index"
                })
            });

            var advanced_fulfillment_permission = "";
            var count = 0;

            this.shipping_services.forEach(x => {
                if (count > 0) advanced_fulfillment_permission += "|";

                advanced_fulfillment_permission += x + "_advanced_fulfillment.index";
                count++;
            })

            this.menu.push({
                id: counter++,
                title: 'advanced_fulfillment',
                link: '',
                submenu: sub,
                permission: advanced_fulfillment_permission
            });

            // Fulfillment EON
            this.menu.push({
                id: counter++,
                title: "fulfillment_eon",
                submenu: [
                    { id: 1, title: 'pendings', link: '/external_shipments/pendings', permission: "eon_pendings.index" },
                    { id: 2, title: 'in_process', link: '/external_shipments/in-process', permission: "eon_in_process.index" },
                    { id: 3, title: 'sent', link: '/external_shipments/sent', permission: "eon_sent.index" },
                    { id: 4, title: 'returned', link: '/external_shipments/returns', permission: "eon_returned.index" }
                ],
                link: '',
                permission: "eon_pendings.index|eon_in_process.index|eon_sent.index|eon_returned.index"
            });

            this.menu.push({
                id: counter++,
                title: "fulfillment_leirem",
                submenu: [
                    { id: 1, title: 'pendings', link: '/shipments_leirem/pendings', permission: "eon_pendings.index" },
                    { id: 2, title: 'in_process', link: '/shipments_leirem/in-process', permission: "eon_in_process.index" },
                    { id: 3, title: 'sent', link: '/shipments_leirem/sent', permission: "eon_sent.index" },
                    // {id: 4, title:'returned', link: '/external_shipments/returns', permission: "eon_returned.index"}
                ],
                link: '',
                permission: "leirem_pendings.index|leirem_in_process.index|leirem_sent.index|leirem_returned.index"
            });

            // Normal Fulfillment
            this.shipping_services.forEach(x => {
                this.menu.push({
                    id: counter++,
                    title: 'fulfillment_' + x,
                    submenu: [
                        {
                            id: 1,
                            title: 'prepaid',
                            link: '',
                            submenu: [
                                { id: 1, title: 'pendings', link: '/shipments/' + x + '/pendings/prepaid', permission: x + "_regular_order.index" },
                                { id: 2, title: 'in_process', link: '/shipments/' + x + '/in-process/prepaid', permission: x + "_pending_regular.index" },
                                { id: 3, title: 'sent', link: '/shipments/' + x + '/sent/prepaid', permission: x + "_processed_regular.index" },
                                { id: 4, title: 'returned', link: '/shipments/' + x + '/returns/prepaid', permission: x + "_regular_returned.index" }
                            ],
                            permission: x + "_regular_order.index|" + x + "_pending_regular.index|" + x + "_processed_regular.index|" + x + "_regular_returned.index"
                        },
                        {
                            id: 2,
                            title: 'cod',
                            link: '',
                            submenu: [
                                { id: 1, title: 'pendings', link: '/shipments/' + x + '/pendings/cod', permission: x + "_cod_order.index" },
                                { id: 2, title: 'in_process', link: '/shipments/' + x + '/in-process/cod', permission: x + "_pending_cod.index" },
                                { id: 3, title: 'sent', link: '/shipments/' + x + '/sent/cod', permission: x + "_processed_cod.index" },
                                { id: 4, title: 'returned', link: '/shipments/' + x + '/returns/cod', permission: x + "_cod_returned.index" }
                            ],
                            permission: x + "_cod_order.index|" + x + "_pending_cod.index|" + x + "_processed_cod.index|" + x + "_cod_returned.index"
                        }
                    ],
                    link: '',
                    permission: x + "_cod_order.index|" + x + "_pending_cod.index|" + x + "_processed_cod.index|" + x + "_cod_returned.index|" + x + "_regular_order.index|" + x + "_pending_regular.index|" + x + "_processed_regular.index|" + x + "_regular_returned.index"
                })
            });

            // Only show for users associated to client
            if (this.$store.state.clientShippingServices != null && this.$store.state.clientShippingServices != undefined) {
                this.menu.push({
                    id: counter++,
                    title: "my_orders",
                    submenu: [],
                    link: '/my_orders',
                    permission: "customer_fulfillment.show|advanced_customer_fulfillment.show|customer_all_access.show"
                })
            }

            // Only show for users associated to client
            if (this.$store.state.clientShippingServices != null && this.$store.state.clientShippingServices != undefined) {
                this.menu.push({
                    id: counter++,
                    title: "my_eorders",
                    submenu: [],
                    link: '/my_eorders',
                    permission: "customer_fulfillment_eon.show|customer_all_access.show"
                })
            }

            this.menu.push({
                id: counter++,
                title: 'catalogs',
                link: '',
                submenu: [
                    { id: 1, title: 'customs_broker', link: '/custom-agents', permission: "customs_broker.index" },
                    { id: 2, title: 'origin_suppliers', link: '/origin-suppliers', permission: "origin_supplier.index" },
                    { id: 3, title: 'gates', link: '/gates', permission: "gate.index" },
                    { id: 4, title: 'boxes', link: '/boxes', permission: "box.index" },
                    { id: 5, title: 'warehouses', link: '/warehouses', permission: "warehouse.index" },
                    { id: 6, title: 'locations', link: '/locations', permission: "location.index" },
                    { id: 7, title: 'services', link: '/services', permission: "service.index" },
                ],
                permission: "customs_broker.index|origin_supplier.index|gate.index|box.index|warehouse.index|location.index|service.index"
            });

            var rep = "reports";
            if (this.$store.state.clientUserId != null && this.$store.state.clientUserId != undefined) rep = "my_reports"
            this.menu.push({
                id: counter++,
                title: rep,
                link: '',
                submenu: [
                    { id: 1, title: 'stock', link: '/reports/stock', permission: "stock.index" },
                    { id: 2, title: 'operations_by_client', link: '/reports/operations_by_customer', permission: "operations_by_client.index" },
                    { id: 3, title: 'operations_by_product', link: '/reports/operations_by_product', permission: "operations_by_product.index" },
                    { id: 4, title: 'products_by_fulfillment', link: '/reports/products_by_fulfillment', permission: "products_by_fulfillment.index" },
                    { id: 5, title: 'products_by_order', link: '/reports/products_by_order', permission: "products_by_order.index" },
                    { id: 6, title: 'annex_documents', link: '/reports/annex_documents', permission: "annex_documents.index" },
                    { id: 7, title: 'operations_by_client_customer', link: '/reports/operations_by_customer_c', permission: "operations_by_client_customer.index" },
                    { id: 8, title: 'operations_by_product_customer', link: '/reports/operations_by_product_c', permission: "operations_by_product_customer.index" },
                    { id: 9, title: 'products_by_fulfillment_customer', link: '/reports/products_by_fulfillment_c', permission: "products_by_fulfillment_customer.index" },
                    { id: 10, title: 'history_product', link: '/reports/history_product', permission: "history_product.index" },
                    { id: 11, title: 'stocks', link: '/reports/stocks', permission: "stock.index" },
                    { id: 12, title: 'cod_by_shipping_service', link: '/reports/cod_by_shipping_service', permission: "cod_by_shipping_service.index" },
                    { id: 13, title: 'stocks_customer', link: '/reports/stocks_c', permission: "stock_customer.index" },
                    { id: 14, title: 'fulfillment_summary_report', link: '/reports/fulfillment_summary', permission: "fulfillment_summary.index" },
                    { id: 15, title: 'balance_pallet', link: '/reports/balance_pallet', permission: "balance_pallet.index" },
                    { id: 16, title: 'boxes_per_fulfillment', link: '/reports/boxes_per_fulfillment', permission: "boxes_per_fulfillment.index" },
                    { id: 17, title: 'condensed_fulfillment', link: '/reports/condensed_fulfillment', permission: "condensed_fulfillment.index" }
                ],
                permission: "stock.index|operations_by_client.index|operations_by_product.index|international_operations.index|products_by_fulfillment.index|products_by_order.index|operations_by_client_customer.index|operations_by_product_customer.index|products_by_fulfillment_customer.index|history_product.index|boxes_per_fulfillment.index|condensed_fulfillment.index"
            });

            this.menu.push({
                id: counter++,
                title: 'customs_patches',
                link: '',
                submenu: [
                    { id: 1, title: 'customs_documents', link: '/customs_patches/customs_documents', permission: "customs_patch.index" },
                    { id: 2, title: 'reports_discharges', link: '/customs_patches/reports/reports_discharges', permission: "customs_patch.index" },
                    { id: 2, title: 'reports_balance', link: '/customs_patches/reports/balance', permission: "customs_patch.index" },
                    { id: 2, title: 'reports_expiration', link: '/customs_patches/reports/expiration', permission: "customs_patch.index" },
                ],
                permission: "customs_patch.index"
            });

            this.menu.push({
                id: counter++,
                title: 'access',
                link: '',
                submenu: [
                    { id: 1, title: 'roles', link: '/roles', permission: "role.index" },
                    { id: 2, title: 'users', link: '/users', permission: "user.index" }
                ],
                permission: "user.index|role.index"
            });

            this.menu.push({
                id: counter++,
                title: 'customs_operator',
                link: '',
                submenu: [
                    {
                        id: 1,
                        title: 'catalogs',
                        submenu: [
                            { id: 1, title: 'taxpayer_data', link: '/customs_data/taxpayer_data', permission: 'customs_operator.index' },
                            { id: 2, title: 'materials', link: '/customs_data/materials', permission: 'customs_operator.index' },
                            { id: 3, title: 'finished_product', link: '/customs_data/finished_product', permission: 'customs_operator.index' },
                            { id: 4, title: 'suppliers', link: '/customs_data/suppliers', permission: 'customs_operator.index' },
                            { id: 5, title: 'co_customers', link: '/customs_data/customers', permission: 'customs_operator.index' },
                            { id: 6, title: 'submanufacturing', link: '/customs_data/submanufacturing', permission: 'customs_operator.index' },
                            { id: 7, title: 'agentsandproxies', link: '/customs_data/agents_and_customs_agents', permission: 'customs_operator.index' },
                            { id: 8, title: 'fixed_assets', link: '/customs_data/fixed_assets', permission: 'customs_operator.index' },
                        ],
                        link: '',
                        permission: 'customs_operator.index'
                    },
                    {
                        id: 2,
                        title: 'interfaces_module',
                        submenu: [
                            { id: 1, title: 'incoming_movements', link: '/customs_data/incoming_movements', permission: 'customs_operator.index' },
                            { id: 2, title: 'outcoming_movements', link: '/customs_data/outcoming_movements', permission: 'customs_operator.index' },
                            { id: 3, title: 'manufacturing_movements', link: '/customs_data/manufacturing_movements', permission: 'customs_operator.index' }
                        ],
                        link: '',
                        permission: 'customs_operator.index'
                    },
                    {
                        id: 3,
                        title: 'customs_module',
                        submenu: [
                            { id: 1, title: 'cm_incoming_movements', link: '/customs_data/cm_incoming_movements', permission: 'customs_operator.index' },
                            { id: 2, title: 'cm_outcoming_movements', link: '/customs_data/cm_outcoming_movements', permission: 'customs_operator.index' },
                            { id: 3, title: 'cm_fixed_assets', link: '/customs_data/cm_fixed_assets', permission: 'customs_operator.index' }
                        ],
                        link: '',
                        permission: 'customs_operator.index'
                    },
                    {
                        id: 4,
                        title: 'reporting_module',
                        submenu: [
                            { id: 1, title: 'rm_incoming_movements', link: '/customs_data/rm_incoming_movements', permission: 'customs_operator.index' },
                            { id: 2, title: 'rm_outcoming_movements', link: '/customs_data/rm_outcoming_movements', permission: 'customs_operator.index' },
                            { id: 3, title: 'rm_balances', link: '/customs_data/rm_balances', permission: 'customs_operator.index' },
                            { id: 4, title: 'rm_disclaimers', link: '/customs_data/rm_disclaimers', permission: 'customs_operator.index' },
                            { id: 5, title: 'rm_settings', link: '/customs_data/rm_settings', permission: 'customs_operator.index' },
                            { id: 6, title: 'rm_expiration', link: '/customs_data/rm_expiration', permission: 'customs_operator.index' }
                        ],
                        link: '',
                        permission: 'customs_operator.index'
                    },
                ],
                permission: "customs_operator.index"
            });

            this.menu.push({
                id: counter++,
                title: 'new_annex',
                link: '',
                submenu: [
                    {
                        id: 1,
                        title: 'catalogs',
                        submenu: [
                            { id: 1, title: 'taxpayer_data', link: '/annex_24/taxpayer_data', permission: 'new_annex_24.index' },
                            { id: 2, title: 'materials', link: '/annex_24/materials', permission: 'new_annex_24.index' },
                            { id: 3, title: 'finished_product', link: '/annex_24/finished_product', permission: 'new_annex_24.index' },
                            { id: 4, title: 'suppliers', link: '/annex_24/suppliers', permission: 'new_annex_24.index' },
                            { id: 5, title: 'co_customers', link: '/annex_24/customers', permission: 'new_annex_24.index' },
                            { id: 7, title: 'customs_agents', link: '/annex_24/agents_and_customs_agents', permission: 'new_annex_24.index' },
                        ],
                        link: '',
                        permission: 'new_annex_24.index'
                    },
                    {
                        id: 3,
                        title: 'customs_module',
                        submenu: [
                            { id: 1, title: 'cm_incoming_movements', link: '/annex_24/cm_incoming_movements', permission: 'new_annex_24.index' },
                            { id: 2, title: 'cm_material_letters', link: '/annex_24/cm_material_letters', permission: 'new_annex_24.index' },
                            { id: 3, title: 'cm_outcoming_movements', link: '/annex_24/cm_outcoming_movements', permission: 'new_annex_24.index' },
                            { id: 4, title: 'cm_fixed_assets', link: '/annex_24/cm_fixed_assets', permission: 'new_annex_24.index' }
                        ],
                        link: '',
                        permission: 'new_annex_24.index'
                    },
                    {
                        id: 4,
                        title: 'reporting_module',
                        submenu: [
                            { id: 1, title: 'rm_incoming_movements', link: '/annex_24/rm_incoming_movements', permission: 'new_annex_24.index' },
                            { id: 2, title: 'rm_outcoming_movements', link: '/annex_24/rm_outcoming_movements', permission: 'new_annex_24.index' },
                            { id: 3, title: 'rm_balances', link: '/annex_24/rm_balances', permission: 'new_annex_24.index' },
                            { id: 4, title: 'rm_disclaimers', link: '/annex_24/rm_disclaimers', permission: 'new_annex_24.index' },
                            { id: 6, title: 'rm_expiration', link: '/annex_24/rm_expiration', permission: 'new_annex_24.index' },
                            { id: 6, title: 'rm_used_materials', link: '/annex_24/rm_used_materials', permission: 'new_annex_24.index' },
                        ],
                        link: '',
                        permission: 'new_annex_24.index'
                    },
                ],
                permission: "new_annex_24.index"
            });

            this.menu.push({
                id: counter++,
                title: 'customs_administrator',
                link: '',
                submenu: [
                    { id: 1, title: 'visitors', link: '/customs_data/visitors', permission: 'customs_administrator.index' },
                    { id: 2, title: 'transportation', link: '/customs_data/transportation', permission: 'customs_administrator.index' },
                ],
                permission: "customs_administrator.index"
            });

            this.menu.push({
                id: counter++,
                title: 'yego_services',
                link: '',
                submenu: [
                    { id: 1, title: 'search', link: '/search', permission: "search.index" },
                    { id: 2, title: 'cod_collection', link: '/cod-collection', permission: "cod_collection.index" },
                    { id: 3, title: 'money_collection', link: '/collection', permission: "collection.index" },
                    { id: 4, title: 'yego_reports', link: '/yego-reports', permission: "administrative_history.index|operative_history.index|balance.index|balancev2.index|address_corrections.index|billing.index|returned_billing.index|minutes.index|orders_identifier.index|products_summary.index|yego_update_order_status.index|payment_pending.index|odoo_report.index|paypal_update_order_status.index|paypal_orders_report.index" },
                    { id: 5, title: 'create_order', link: '/create_order', permission: "shipment.index" },
                    { id: 6, title: 'create_multiple_orders', link: '/create_multiple_orders', permission: "create_multiple_orders.index" },
                ],
                permission: "search.index|cod_collection.index|collection.index|shipment.index|create_multiple_orders.index|administrative_history.index|operative_history.index|balance.index|balancev2.index|address_corrections.index|billing.index|returned_billing.index|minutes.index|orders_identifier.index|products_summary.index|yego_update_order_status.index|payment_pending.index|odoo_report.index|paypal_update_order_status.index|paypal_orders_report.index"
            });

            this.menu.push({
                id: counter++,
                title: 'track_n_trace',
                link: '',
                submenu: [
                    { id: 1, title: 'tnt_extra_status', link: '/tnt_extra_status', permission: "extra_status.index" },
                    { id: 2, title: 'tnt_daily_cutoff', link: '/tnt_daily_cutoff', permission: "daily_cutoff.index" },
                    { id: 3, title: 'tnt_closing_minute', link: '/tnt_closing_minute', permission: "closing_minute.index" },
                    { id: 4, title: 'tnt_without_movement', link: '/tnt_without_movement', permission: "without_movement.index" },
                    { id: 5, title: 'tnt_rejections', link: '/tnt_rejections', permission: "rejections.index" },
                    { id: 6, title: 'tnt_delivered', link: '/tnt_delivered', permission: "delivered.index" }
                ],
                permission: "extra_status.index|daily_cutoff.index|closing_minute.index|without_movement.index|rejections.index|delivered.index"
            });

            this.menu.push({
                id: counter++,
                title: 'orders_pending',
                link: '/stuck_orders',
                submenu: [],
                permission: "orders_pendings.show|orders_pendings.index"
            });

            this.menu.push({
                id: counter++,
                title: 'stuck_external_order',
                link: '/stuck_external_orders',
                submenu: [],
                permission: "stuck_external_orders.show|stuck_external_orders.index"
            });

            this.menu.push({
                id: counter++,
                title: 'orders_possible_fraud',
                link: '/orders_possible_fraud',
                submenu: [],
                // permission: "orders_possible_fraud.show|orders_possible_fraud.index"
                permission: "orders_possible_fraud.show|orders_possible_fraud.index"
            });

            this.menu.push({
                id: counter++,
                title: 'liverpool',
                link: '',
                submenu: [
                    { id: 1, title: 'acceptance', link: '/liverpool/acceptance', permission: "liverpool.acceptance" },
                    { id: 2, title: 'confirmation', link: '/liverpool/confirmation', permission: "liverpool.confirmation" },
                    { id: 3, title: 'history', link: '/liverpool/history', permission: "liverpool.history" },
                ],
                permission: "liverpool.show|liverpool.index"
            });

            this.menu.push({
                id: counter++,
                title: 'entrance',
                link: '',
                submenu: [
                    { id: 1, title: 'visitors', link: '/entrance/visitors', permission: "visitors.index" },
                    { id: 2, title: 'carriers', link: '/entrance/carriers', permission: "carriers.index" },
                    { id: 2, title: 'attendances', link: '/entrance/attendances', permission: "attendances.index" },
                ],
                permission: "visitors.index|carriers.index|attendances.index"
            });
        }
    }
};
</script>
<style lang="scss">
.linkActiveClass,
a.v-list-item--active {
    // background-color: #009cde !important;
    background-color: #9fb700 !important;
    color: #FFF !important;
}

.v-list-group__header,
.v-list-item {
    padding-right: 5px !important;
    padding-left: 10px !important;
    min-height: 32px !important;
    max-height: 32px !important;
}

.v-list-group__items {
    padding-left: 10px;
}

.v-list-group__items .v-list-item {
    padding-left: 20px;
}

.list-item {
    color: var(--v-sidebarText-base) !important;
}

.active-list-item {
    background-color: var(--v-primary-base);
    color: var(--v-sidebarTextActive-base) !important;
}

.item {
    min-height: 180px !important;
    max-height: 180px !important;
}

// .v-list-group__items .v-list-item.border-orange {
//  padding-left: 14px !important;
// }

// .v-list-item.border-orange{
//  padding-left: 10px !important;
// }

.v-list--dense .v-list-item .v-list-item__icon {
    margin-top: 7px;
    margin-bottom: 7px;
}

.sidemenu-border {
    -webkit-border-radius: 0px 70px 70px 0px;
    -moz-border-radius: 0px 70px 70px 0px;
    border-radius: 0px 70px 70px 0px;
}

.right-direction {
    direction: ltr !important;
}

.left-direction {
    direction: rtl !important;
}
</style>
