import Vue from 'vue'
import Vuex from 'vuex'
import VueGates from 'vue-gates';
import ModuleCustomsPatches from './modules/customs_patches.module';
import ModuleCustomsPatchesReport from './modules/reports/customs_patches_reports.module';
Vue.use(Vuex)
Vue.use(VueGates, {
  persistent: true
});

export default new Vuex.Store({
  state: {
    module: 'headers.n_pendings',
    minimenu: false,
    menu: true,
    layout: 'loading',
    loggedIn: false,
    loggedUsername: '',
    loggedPicture: null,
    loggedUserRole: '',
    loggedUserEmail: '',
    loggedUserPermissions: '',
    clientUserId: null, // Associated client to userexit
    clientUserIsCod: false, // Check if associated client is cod or both prepaid and cod
    warehouseName: localStorage.getItem('warehouseName') || 'chronos',
    warehouseId: localStorage.getItem('warehouse_id') || 1,
    bottom: localStorage.getItem('bottom') || 0,
    clientId: '',
    clientShippingServices: [],
    clientName: '',
    trace_id: '', // id to cancel or confirm selected orders in advanced fulfillment view
    trace_id_loaded: false,
    language: localStorage.getItem('language') || 'es',
    chronosApi: process.env.NODE_ENV === 'production' ? 'https://api.eonwms.com/' : 'https://apisandbox.eonwms.com/',
    // chronosApi: 'http://localhost:3000/',
    rfidApi: process.env.NODE_ENV === 'production' ? 'https://rfid.eonwms.com/' : 'https://rfiddev.eonwms.com/',
    // rfidApi: 'https://rfid.eonwms.com/', // servidor daedalus
    eonShopsApi: 'https://ehub.yego.mx/',
    // eonShopsApi: 'http://localhost:3000/',
    printerServiceApi: 'http://localhost:5005/', // servicio local para imprimir y obtener las imprenpm soras
    loaderProps: {
      visible: false,
      color: 'azul',
      overlarColor: 'grey',
      class: 'white--text',
      text: localStorage.getItem('language') == 'en' ? "LOADING..." : "CARGANDO...",
      lineColor: 'white',
    },
    shipping_services: [
      // { value: "MIN", text: "99MINUTOS" }, 
      { value: "AFIMEX", text: "AFIMEX" },
      { value: "CEXPRESS", text: "CEXPRESS" },
      // { value: "DHL", text: "DHL" },
      { value: "FEDEX", text: "FEDEX" }, 
      { value: "HERMES", text: "HERMES" },
      { value: "JTEXPRESS", text: "JTEXPRESS" },
      { value: "MERQ", text: "MERQ" },
      { value: "OTRA", text: "OTRA" },
      // { value: "PAQUETEXPRESS", text: 'PAQUETEXPRESS'},
      // { value: "QUIKEN", text: "QUIKEN" },
      { value: "UPS", text: "UPS" },
      { value: "USPS", text: "USPS" },
      { value: "YEGO", text: "YEGO" },
    ],
    years_catalog: [2025, 2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016],
  },
  getters: {
    language: state => state.language
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    customs_patches: ModuleCustomsPatches,
    customs_patches_reports: ModuleCustomsPatchesReport
  }
})
